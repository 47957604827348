import { useContext } from 'react';

import { ToastComponent } from '../../components/ToastComponent';
import {
  GET_TRANSPORTCOMPANIES_CATALOG,
  GET_TRANSPORTCOMPANIES_CATALOG_SUCCESS,
  GET_TRANSPORTCOMPANIES_CATALOG_ERROR,
  POST_TRANSPORTCOMPANIES_CATALOG,
  POST_TRANSPORTCOMPANIES_CATALOG_SUCCESS,
  POST_TRANSPORTCOMPANIES_CATALOG_ERROR,
  PUT_TRANSPORTCOMPANIES_CATALOG,
  PUT_TRANSPORTCOMPANIES_CATALOG_SUCCESS,
  PUT_TRANSPORTCOMPANIES_CATALOG_ERROR,
  DELETE_TRANSPORTCOMPANIES_CATALOG,
  DELETE_TRANSPORTCOMPANIES_CATALOG_SUCCESS,
  DELETE_TRANSPORTCOMPANIES_CATALOG_ERROR,
} from '../../constants/constantsRed';
import { StoreContext } from '../../context/Provider';
import useApi from '../../services/request.service';

export const useTransportCompaniesCatalogs = () => {
  const context = useContext(StoreContext);
  const {
    dispatchGetTransportCompaniesCatalog,
    dispatchDeleteTransportCompaniesCatalog,
    dispatchPostTransportCompaniesCatalog,
    dispatchPutTransportCompaniesCatalog,
  } = context;
  const { genericApiConfiguration } = useApi();

  const getTransportCompaniesCatalog = async () => {
    dispatchGetTransportCompaniesCatalog({
      type: GET_TRANSPORTCOMPANIES_CATALOG,
    });
    try {
      const result: any = await genericApiConfiguration(
        '/transport-companies',
        'GET',
        {},
        {},
        {}
      );
      if (result.status === 200) {
        const payload = result.data.payload;
        dispatchGetTransportCompaniesCatalog({
          type: GET_TRANSPORTCOMPANIES_CATALOG_SUCCESS,
          payload: payload,
        });
      }
      return result;
    } catch (error: any) {
      ToastComponent(
        'warning',
        'Error',
        'La operación falló, inténtelo más tarde por favor.'
      );
      dispatchGetTransportCompaniesCatalog({
        type: GET_TRANSPORTCOMPANIES_CATALOG_ERROR,
        payload: error.message,
      });
      return 'error';
    }
  };

  const postTransportCompaniesCatalog = async (data: any) => {
    dispatchPostTransportCompaniesCatalog({
      type: POST_TRANSPORTCOMPANIES_CATALOG,
    });
    try {
      const result: any = await genericApiConfiguration(
        '/transport-companies',
        'POST',
        data,
        {},
        {}
      );
      if (result.status === 200) {
        ToastComponent('success', '', 'Se ha añadido el registro correctamente');
        const payload = result.data.payload;
        dispatchPostTransportCompaniesCatalog({
          type: POST_TRANSPORTCOMPANIES_CATALOG_SUCCESS,
          payload: payload,
        });
      }
      return result;
    } catch (error: any) {
      dispatchPostTransportCompaniesCatalog({
        type: POST_TRANSPORTCOMPANIES_CATALOG_ERROR,
        payload: error.message,
      });
      return 'error';
    }
  };

  const putTransportCompaniesCatalog = async (data: any) => {
    dispatchPutTransportCompaniesCatalog({
      type: PUT_TRANSPORTCOMPANIES_CATALOG,
    });
    try {
      const result: any = await genericApiConfiguration(
        '/transport-companies',
        'PUT',
        data,
        {},
        {}
      );
      if (result.status === 200) {
        ToastComponent('success', '', 'Se ha editado el registro correctamente');
        const payload = result.data.payload;
        dispatchPutTransportCompaniesCatalog({
          type: PUT_TRANSPORTCOMPANIES_CATALOG_SUCCESS,
          payload: payload,
        });
      }
      return result;
    } catch (error: any) {
      dispatchPutTransportCompaniesCatalog({
        type: PUT_TRANSPORTCOMPANIES_CATALOG_ERROR,
        payload: error.message,
      });
      return 'error';
    }
  };

  const deleteTransportCompaniesCatalog = async (id: string) => {
    dispatchDeleteTransportCompaniesCatalog({
      type: DELETE_TRANSPORTCOMPANIES_CATALOG,
    });
    try {
      const result: any = await genericApiConfiguration(
        '/transport-companies/{id}',
        'DELETE',
        {},
        { ids: id },
        {}
      );

      if (result.status === 200) {
        const payload = result.data.payload;
        dispatchDeleteTransportCompaniesCatalog({
          type: DELETE_TRANSPORTCOMPANIES_CATALOG_SUCCESS,
          payload: payload,
        });
      }
      return result;
    } catch (error: any) {
      dispatchDeleteTransportCompaniesCatalog({
        type: DELETE_TRANSPORTCOMPANIES_CATALOG_ERROR,
        payload: error.message,
      });
      return 'error';
    }
  };

  return {
    getTransportCompaniesCatalog,
    postTransportCompaniesCatalog,
    putTransportCompaniesCatalog,
    deleteTransportCompaniesCatalog,
  };
};

import { Column } from 'primereact/column';
import { useEffect, useState } from 'react';
import Cookies from 'universal-cookie';

import { RadioButtonComponent } from '../../../components/RadioButtonComponent';
import { TableComponent } from '../../../components/Table';
import { IInventaryTableProps } from '../../../interfaces/interfaceInventory';
import { createQueryParams } from '../../../utils/utils';
import { getColumnsInFilter } from '../utils/ColumnsInFilter';
import { cordinatesInventory, getActionsTemplate } from './BodyTemplatesTable';
import { ModifyDisableSam } from './DisableSam';
import { Filters } from './Filters';
import { ModifyWhiteListSam } from './ModifyWhiteListSam';
import { RechargesCounterHistoryModal } from './RechargesCounterHistoryModal';

const cookies = new Cookies(null, { path: '/' });

export const InventaryTable = (props: IInventaryTableProps) => {
  const {  getInventoryData, inventoryExcel, loader, samListData, samListDataTemp, setSamListDataTemp, setOpenRightMenu, setDataRow, selectMenu, setUpdateTable } = props;

  //States Zone
  
  const [sort, setSort] = useState([{ field: '', order: -1 }]);
  const [showWhiteList, setShowWhiteList] = useState<boolean>(false);
  const [whiteListSam, setWhiteListSam] = useState<any>({});
  const [columnsDisplayInTable, setColumnsDisplayInTable] = useState<any>([]);
  const [selectedSam, setSelectedSam] = useState();
  const [
    selectedSamToHistoryRechargeModal,
    setSelectedSamToHistoryRechargeModal,
  ] = useState();
  const [filterGlobal, setFilterGlobal] = useState<string>('');
  const [filterIdDevice, setFilterIdDevice] = useState<string>('');
  const [showHistoryRechageModal, setShowHistoryRechageModal] =
  useState<boolean>(false);
  const [showDisableSam, setShowDisableSam] = useState<boolean>(false);
  const [disableSam, setDisableSam] = useState<any>({});

  //Hooks Zone

  useEffect(() => {
    if (filterGlobal === '' && filterIdDevice === '') {
      setSamListDataTemp(samListData);
    } else {
      let filteredData = samListData;

      if (filterGlobal !== '') {
        filteredData = filteredData.filter((x: any) =>
          x?.SamId?.includes(filterGlobal)
        );
      }

      if (filterIdDevice !== '') {
        filteredData = filteredData.filter((x: any) =>
          x?.NumberDevice?.includes(filterIdDevice)
        );
      }

      setSamListDataTemp(filteredData);
    }
  }, [filterGlobal, filterIdDevice]);

  //Table Zone

  const checkBody: any = (rowData: any, columnData: any) => {
    return (
      <RadioButtonComponent
        inputId={rowData.sam_id}
        name={rowData.sam_id}
        checked={rowData[columnData['field']]}
      />
    );
  };

  const statusCheckbox: any = (rowData: any, columnData: any) => {
    let field = rowData[columnData['field']];
    let color = 'bg-black';
    switch (field) {
      case 'Activar':
        color = 'bg-official_green';
        break;
      case 'Emitir':
        color = 'bg-dark_blue';
        break;
      case 'Inactivar':
        color = 'bg-ligth_grey';
        break;
      case 'Dañado':
        color = 'bg-red';
        break;
      case 'Inicializar':
        color = 'bg-ligth_blue';
        break;

      default:
        break;
    }
    return <div className={`h-5 w-5 rounded-2xl ${color}`}></div>;
  };

  const editSamHandler = (row: any) => {
    setDataRow(row);
    setOpenRightMenu(true);
    selectMenu('Actualizar');
    setUpdateTable(() => updateTable);
  };

  const updateTable = () => {
    setShowWhiteList(false);
    setShowDisableSam(false);
    setOpenRightMenu(false);
    selectMenu('');
  };

  const openWhiteListSam = (row: any) => {
    if (
      row.SamType === 'Recarga de Monedero, Crédito y BPD' ||
      row.SamType === 'Recarga de Monedero y Crédito' ||
      row.SamType === 'Recarga de Monedero' ||
      row.SamType === 'Recarga de BPD'
    ) {
      setWhiteListSam({
        data: row,
      });
      setShowWhiteList(true);
    }
  };

  const closeWhiteListSam = (value: boolean) => {
    setShowWhiteList(value);
  };

  const openDisableSam = (row: any) => {
    setDisableSam({
      data: row,
    });
    setShowDisableSam(true);
  };

  const closeDisableSam = (value: boolean) => {
    setShowDisableSam(value);
  };

  const openHistoryRechageModal = (row: any) => {
    setSelectedSamToHistoryRechargeModal(row);
    setShowHistoryRechageModal(true);
  };

  const closeHistoryRechageModal = () => {
    setShowHistoryRechageModal(false);
  };

  const handlerColumn = (data: any) => {
    let newOrderColumns: any = [...data];
    let columns = newOrderColumns.map((item: any) => item.field);
    localStorage.setItem('columns_order', columns);

    let save_order = localStorage.getItem('columns_order')!;
    const order = save_order.split(',');

    const sortedColumns = [];

    for (const field of order) {
      const column = allColumns.find((col: any) => col.field === field);

      if (column) {
        sortedColumns.push(column);
      }
    }

    setColumnsDisplayInTable(sortedColumns);

    const columnsStr = sortedColumns.map((column: any) => column.field);
    const stringColumns = columnsStr.join(',');
    cookies.set(
      `${localStorage.getItem('userId')}|ColumnOrder`,
      stringColumns,
      { maxAge: 30 * 24 * 60 * 60 }
    );
  };

  /***
   * Filters zone
   */

  const handlerExcelExport = (queryRequest: string) => {
    const columnsQueryParams = createQueryParams(
      'columnsToDisplay',
      columnsDisplayInTable.map((item: any) => item.field)
    );
    inventoryExcel(queryRequest, columnsQueryParams);
  };

  async function handlerFilters(queryRequest: string) {
    return await getInventoryData(queryRequest);
  }

  /***
   * --/ End Filters zone
   */

  let defaultColumns = [
    {
      field: 'SamId',
      header: 'SAM ID',
      sortable: true,
      width: '100px',
      isFilter: true,
      dataType: undefined,
    },
    {
      field: 'NumberDevice',
      header: 'Id de equipo',
      sortable: true,
      isFilter: true,
      dataType: undefined,
    },
    {
      field: 'SamStatus',
      header: 'Estatus',
      sortable: true,
      body: statusCheckbox,
      isFilter: true,
      dataType: 'boolean',
    },
    {
      field: 'WhiteList',
      header: 'Lista blanca',
      sortable: true,
      body: checkBody,
      isFilter: true,
      dataType: 'boolean',
    },
    {
      field: 'DeviceType',
      header: 'Dispositivo',
      sortable: true,
      isFilter: true,
      dataType: undefined,
    },
    {
      field: 'SamType',
      header: 'Tipo de SAM',
      sortable: true,
      isFilter: true,
      dataType: undefined,
    },
    {
      field: 'LocationGpsObject',
      header: 'Coordenadas',
      sortable: false,
      isFilter: false,
      dataType: undefined,
      body: cordinatesInventory,
    },
    {
      field: 'TechnologicalOperator',
      header: 'Operador',
      sortable: true,
      isFilter: true,
      dataType: undefined,
    },
    {
      field: 'TransportCompanyEUR',
      header: 'Compañía - EUR',
      sortable: true,
      isFilter: true,
      dataType: undefined,
    },
    {
      field: 'acciones',
      header: 'Acciones',
      sortable: false,
      isFilter: false,
      dataType: undefined,
      body: getActionsTemplate(
        editSamHandler,
        openDisableSam,
        openWhiteListSam
      ),
    },
  ];

  const allColumns = [
    ...defaultColumns,
    ...getColumnsInFilter(openHistoryRechageModal),
  ];

  const setDefaultColumns = () => {
    let save_order = localStorage.getItem('columns_order')!;
    if (save_order === '') {
      let saveOrderArray = defaultColumns.map(column => column.field);
      let strSaveOrder = saveOrderArray.join(',');
      save_order = strSaveOrder;
    }

    const order = save_order.split(',');

    const sortedColumns = [];
    for (const field of order) {
      const column = allColumns.find((col: any) => col.field === field);
      if (column) sortedColumns.push(column);
    }

    defaultColumns = sortedColumns;
  };

  if (
    cookies.get(`${localStorage.getItem('userId')}|ColumnOrder`) !== null &&
    cookies.get(`${localStorage.getItem('userId')}|ColumnOrder`) !== '' &&
    cookies.get(`${localStorage.getItem('userId')}|ColumnOrder`) !== undefined
  ) {
    let userSession = localStorage.getItem('userId')!;

    let lastColumnsOrder = cookies.get(`${userSession}|ColumnOrder`)!;
    localStorage.setItem('columns_order', lastColumnsOrder);
    setDefaultColumns();
  } else {
    const sortedColumns = [];
    const order = defaultColumns.map((column: any) => column.field);
    for (const field of order) {
      const column = allColumns.find((col: any) => col.field === field);
      if (column) sortedColumns.push(column);
    }
    defaultColumns = sortedColumns;
  }

  const getColumnsOrder = (currentColumns: any) => {
    let columns = currentColumns.columns.map((item: any) => item.props.field);
    localStorage.setItem('columns_order', columns);
    cookies.set(`${localStorage.getItem('userId')}|ColumnOrder`, columns, {
      maxAge: 30 * 24 * 60 * 60,
    });
  };

  const getColumnsToDisplayIntable = () => {
    let columns = columnsDisplayInTable.map((column: any, index: any) => {
      const key = index.toString();
      return (
        <Column
          key={key}
          field={column.field}
          filterField={column.field}
          dataType={column.isFilter ? column.dataType : undefined}
          header={column.header}
          bodyClassName={'!text-black'}
          style={{
            flexGrow: 1,
            flexBasis: '100px',
          }}
          headerClassName="  !bg-tableHeader !text-withe!justify-between "
          sortable={column.sortable}
          filterMenuStyle={{ width: '14rem' }}
          filter={false}
          body={column.body}
        />
      );
    });

    columns.unshift(
      <Column
        selectionMode="multiple"
        headerStyle={{ width: '3rem' }}
        key={0}></Column>
    );

    return columns;
  };

  return (
    <>
      <Filters
        setFilterGlobal={setFilterGlobal}
        setDeviceFilter={setFilterIdDevice}
        columnList={allColumns}
        handlerColumn={handlerColumn}
        handlerFilters={handlerFilters}
        handlerExcelExport={handlerExcelExport}
        columnSelected={defaultColumns}
        selectedSam={selectedSam}
        setSelectedSam={setSelectedSam}
        getSamData={getInventoryData}
      />
      <div className="h-full flex flex-col w-full overflow-y-auto relative ">
        <TableComponent
          value={samListDataTemp}
          sortField="name"
          responsiveLayout="scroll"
          globalFilterFields={[]}
          resizableColumns
          setSort={setSort}
          sort={sort}
          reorderableColumns={true}
          onColReorder={(e: any) => getColumnsOrder(e)}
          OnEmptyMessage="No se encontraron registros, seleccione algún filtro y presione el botón 'Enviar' para mostrar resultados"
          selectionMode={'checkbox'}
          selection={selectedSam}
          loading={loader}
          onSelectionChange={(e: any) => setSelectedSam(e.value)}>
          {getColumnsToDisplayIntable()}
        </TableComponent>
      </div>
      {showWhiteList === true ? (
        <ModifyWhiteListSam
          setDataRow={whiteListSam}
          handlerUpdateData={updateTable}
          show={showWhiteList}
          closeMenu={closeWhiteListSam}
        />
      ) : (
        <></>
      )}
      {showDisableSam === true ? (
        <ModifyDisableSam
          setDataRow={disableSam}
          handlerUpdateData={updateTable}
          show={showDisableSam}
          closeMenu={closeDisableSam}
        />
      ) : (
        <></>
      )}
      {showHistoryRechageModal === true ? (
        <RechargesCounterHistoryModal
          show={true}
          closeMenu={closeHistoryRechageModal}
          samSelected={selectedSamToHistoryRechargeModal}
        />
      ) : (
        <></>
      )}
    </>
  );
};

import { useContext } from 'react';

import { ToastComponent } from '../../components/ToastComponent';
import {
  PUT_RECHARGECOUNTER,
  PUT_RECHARGECOUNTER_SUCCESS,
  PUT_RECHARGECOUNTER_ERROR,
} from '../../constants/constantsRed';
import { StoreContext } from '../../context/Provider';
import useApi from '../../services/request.service';

export const useRechargeCourter = () => {
  const context = useContext(StoreContext);
  const { dispatchPutRechargeCounter } = context;
  const { genericApiCallApiBilling } = useApi();

  const putRechargeCounter = async (data: any, headers: any) => {
    dispatchPutRechargeCounter({ type: PUT_RECHARGECOUNTER });
    try {
      const result: any = await genericApiCallApiBilling(
        '/v1.0/sam-inventory/counters',
        'PUT',
        data,
        {},
        headers
      );
      if (result.status === 200) {
        const payload = result.data.payload;
        dispatchPutRechargeCounter({
          type: PUT_RECHARGECOUNTER_SUCCESS,
          payload: payload,
        });
        ToastComponent('success', '', 'Contadores agregados correctamente');
      }
      return result;
    } catch (error: any) {
      ToastComponent(
        'warning',
        'Error',
        'La operación falló, inetnet más tarde por favor.'
      );
      dispatchPutRechargeCounter({
        type: PUT_RECHARGECOUNTER_ERROR,
        payload: error.message,
      });
      return 'error';
    }
  };

  return { putRechargeCounter };
};

import { ChevronDownIcon } from '@heroicons/react/24/outline';
import React, { useContext, useEffect, useState } from 'react';
import { BiLogOut } from 'react-icons/bi';

import userIcon from '../../../assets/images/user-header.png';
import { authService } from '../../../authentication/authService';
import { useSettings } from './../../../hooks/settings/useSettings';
import { validateUserWebPermission } from '../../../utils/utils';
import { useNavigate } from 'react-router-dom';

interface IModule {
  moduleId: number;
  rol: string;
}

interface IUserData {
  userName: string;
  firstName: string;
  surName: string;
  email: string;
  rol: string;
  roleName: string;
  phone: string;
  modules: IModule[];
}

interface IUserMenu {
  refCloseProfileCard: any;
  showPopover: boolean;
  setShowPopover: (any: boolean) => void;
}

export const UserMenu = (props: IUserMenu) => {
  const { refCloseProfileCard, showPopover, setShowPopover } = props;

  const { getPersonalData } = useSettings();
  const navigate = useNavigate();

  const [user, setUser] = useState<IUserData>({
    userName: '',
    firstName: '',
    surName: '',
    email: '',
    rol: '',
    roleName: '',
    phone: '',
    modules: [],
  });

  const logout = () => {
    authService.logout();
  };

  useEffect(() => {
    const getData = async () => {
      let response = await getPersonalData();
      if (response !== undefined) {
        setUser(response);
        localStorage.setItem('permitions_user', response['permissionValue']);
        localStorage.setItem('userId', response['userId']);
        const validateWebUser = validateUserWebPermission();
        if (validateWebUser === false) {
          navigate('/denegado');
          return;
        }
      } else {
        logout();
      }
    };

    getData();
  }, []);

  return (
    <div ref={refCloseProfileCard} className=" flex flex-col w-full relative">
      <div
        onClick={() => setShowPopover(!showPopover)}
        className="flex w-full h-full flex-row text-secondary-contrast-color-text  rounded-md hover:cursor-pointer ">
        <div className="flex h-full flex-row w-full lg:content-center xl:content-center 2xl:content-center lg:items-center xl:items-center 2xl:items-center lg:justify-center xl:justify-center 2xl:justify-center">
          <div className="flex items-center flex-shrink-0">
            <img src={userIcon} alt="Usuario" className="h-8 mr-2" />
          </div>
          <div className="flex justify-center items-center content-center">
            <ChevronDownIcon className=" h-6 w-6 stroke-slate-600 dark:stroke-[#839AE6]" />
          </div>
        </div>
      </div>
      {showPopover ? (
        <ul className="flex w-64 flex-col pt-0 absolute top-[61px] divide-y right-1 rounded-b-lg bg-white drop-shadow-2xl p-2 ">
          <div className="flex flex-col w-full break-all py-3">
            <div className="flex justify-between text-official_green cursor-pointer">
              <img src={userIcon} alt="Usuario" className="h-8 mr-2" />

              <BiLogOut className="h-7 w-7" onClick={() => logout()} />
            </div>
            <div className={`text-xs font-bold  py-1 truncate h-8`}>
              {user?.userName} {user?.firstName} {user?.surName}
            </div>
            <p className={` text-xs ml-2`}>{user?.email}</p>
            <p className={` text-xs ml-2`}>{user?.phone}</p>
            <p className={` text-xs ml-2`}>{user?.roleName}</p>
          </div>
          <li
            className={` cursor-pointer h-10 justify-start content-center items-center `}>
            Mi cuenta
          </li>
          <li
            className={` cursor-pointer h-10 justify-start content-center items-center `}>
            Aviso de privacidad
          </li>
          <li
            className={` cursor-pointer h-10 justify-start content-center items-center `}>
            Términos y condiciones
          </li>
        </ul>
      ) : null}
    </div>
  );
};

import { Tooltip } from 'primereact/tooltip';
import {
  LuFileEdit,
  LuFileMinus2,
  LuFilePlus2,
  LuPower,
  LuPowerOff,
} from 'react-icons/lu';

import { PermitsList } from '../../../permitsList';
import { validatePermissions } from '../../../utils/utils';

export const cordinatesInventory = (row: any) => {
  const pattern = /POINT \((-?\d+(?:\.\d+)?) (-?\d+(?:\.\d+)?)\)/;

  const match = row?.LocationGpsObject?.match(pattern);

  if (match) {
    const primerValor = parseFloat(match[1]).toFixed(7);
    const segundoValor = parseFloat(match[2]).toFixed(7);
    return (
      <div className="flex flex-col w-full justify-around ">
        <p>Latitud : {primerValor}</p>
        <p>Longitud : {segundoValor}</p>
      </div>
    );
  } else {
    return (
      <div>
        <p>{row.LocationGpsObject}</p>
      </div>
    );
  }
};

export const getActionsTemplate = (
  editSamHandler: any,
  openDisableSam: any,
  openWhiteListSam: any
) => {
  const actionsInventory = (row: any) => {
    return (
      <div className="flex w-full justify-around text-xl gap-3">
        <Tooltip target=".custom-target-icon" />
        <div>
          {validatePermissions(PermitsList.SAM_INVENTORY_EDIT) ? (
            <LuFileEdit
              className="cursor-pointer custom-target-icon"
              data-pr-tooltip={'Editar SAM'}
              data-pr-position="top"
              onClick={() => {
                editSamHandler(row);
              }}
            />
          ) : (
            <></>
          )}
        </div>
        <div>
          {row.SamStatus === 'Activar' ? (
            validatePermissions(PermitsList.SAM_INVENTORY_INACTIVATE) ? (
              <LuFileMinus2
                className="cursor-pointer text-official_green custom-target-icon"
                data-pr-tooltip={'Inactivar SAM'}
                data-pr-position="top"
                onClick={() => openDisableSam(row)}
              />
            ) : (
              <></>
            )
          ) : validatePermissions(PermitsList.SAM_INVENTORY_ACTIVATE) ? (
            <LuFilePlus2
              className="cursor-pointer text-red custom-target-icon"
              data-pr-tooltip={'Activar SAM'}
              data-pr-position="top"
              onClick={() => openDisableSam(row)}
            />
          ) : (
            <></>
          )}
        </div>
        <div>
          {row.WhiteList === true ? (
            validatePermissions(PermitsList.SAM_INVENTORY_WL_REMOVE) ? (
              <LuPower
                className="cursor-pointer text-official_green custom-target-icon"
                data-pr-tooltip={'Quitar SAM a lista blanca'}
                data-pr-position="top"
                onClick={() => openWhiteListSam(row)}
              />
            ) : (
              <></>
            )
          ) : validatePermissions(PermitsList.SAM_INVENTORY_WL_ADD) ? (
            <LuPowerOff
              className="cursor-pointer text-red custom-target-icon"
              data-pr-tooltip={'Agregar SAM a lista blanca'}
              data-pr-position="top"
              onClick={() => openWhiteListSam(row)}
            />
          ) : (
            <></>
          )}
        </div>
      </div>
    );
  };
  return actionsInventory;
};

import { getInventory_Red } from "../../Reducers/inventory/get-red"
import { useReducer } from "react"
import { globalInitialState } from "../initialStateGlobal"

export const useGetInventoryState = () =>{
  const [state, dispatch] = useReducer(getInventory_Red, globalInitialState);
  return {state, dispatch};
}

export const useGetInvetoryToExcelState = () =>{
    const [state, dispatch] = useReducer(getInventory_Red, globalInitialState);
    return {state, dispatch};
}
import { FunctionComponent, useEffect, useState } from 'react';

import { ButtonComponent } from '../../../components/ButtonComponen';
import { DialogPopUpComponent } from '../../../components/DialogPopUpComponent';
import { InputComponent } from '../../../components/InputComponent';
import { useRechargeCourter } from '../../../hooks/inventory/useRechargeCourter';
import { getCurrentPosition } from '../../../utils/utils';

interface IWhiteListSamProps {
  handlerUpdateData?: any;
  show: boolean;
  closeMenu?: any;
  selectedSam: any;
  setSelectedSam: any;
}

export const RechargeCouterModal = (props: IWhiteListSamProps) => {
  const { handlerUpdateData, show, closeMenu, selectedSam, setSelectedSam } = props;

  const [visible, setVisible] = useState<boolean>(false);
  const [value, setValue] = useState<string>('');
  const [header] = useState<string>('Agregar contadores');

  const { putRechargeCounter } = useRechargeCourter();

  useEffect(() => {
    setVisible(show);
  }, [props]);

  const close = (value?: boolean) => {
    setVisible(value || true);
    closeMenu(false);
  };

  const bodyContent = () => {
    return (
      <div className="w-full h-full flex p-6 justify-center">
        <InputComponent
          type="number"
          name="Contadores"
          placeholder="Contadores"
          label="Agregar contadores a las SAM"
          value={value}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setValue(e.target.value)
          }
        />
      </div>
    );
  };

  const footerContent = () => {
    return (
      <div className="w-full h-full flex pb-4 justify-center">
        <ButtonComponent
          label="Cancelar"
          onClick={() => close(false)}
          className="p-button-text !bg-deleteButton !text-white"
        />
        <ButtonComponent
          label="Aceptar"
          onClick={() => getResponse()}
          disbaled={value === ''}
        />
      </div>
    );
  };

  const getResponse = async () => {
    let data = selectedSam.map((item: any) => ({
      amount: value,
      samId: item.Id,
    }));

    let coords = await getCurrentPosition();

    const headers = {
      locationGPS: `${coords.latitude}, ${coords.latitude}`,
      appId: 'web',
    };

    let response = await putRechargeCounter(data, headers);
    if (response.status === 200) {
      setValue('');
      handlerUpdateData();
      setSelectedSam();
    }
  };

  return (
    <div>
      {visible === true ? (
        <DialogPopUpComponent
          show={visible}
          header={header}
          body={bodyContent()}
          footer={footerContent()}
          close={close}
        />
      ) : (
        <></>
      )}
    </div>
  );
};

import { useContext } from 'react';

import { ToastComponent } from '../../components/ToastComponent';
import {
  GET_SAM_TYPES,
  GET_SAM_TYPES_ERROR,
  GET_SAM_TYPES_SUCCESS,
} from '../../constants/constantsRed';
import { StoreContext } from '../../context/Provider';
import useApi from '../../services/request.service';

export const useSamTypes = () => {
  const context = useContext(StoreContext);
  const { dispatchGetSamTypes } = context;
  const { genericApiCallApiBilling } = useApi();

  const getSamTypes = async () => {
    dispatchGetSamTypes({ type: GET_SAM_TYPES });
    try {
      const result: any = await genericApiCallApiBilling(
        '/v1.0/sam-types',
        'GET',
        {},
        {},
        {}
      );
      if (result.status === 200) {
        const payload = result.data.payload;
        dispatchGetSamTypes({
          type: GET_SAM_TYPES_SUCCESS,
          payload: payload,
        });
      }
      return result;
    } catch (error: any) {
      ToastComponent(
        'warning',
        'Error',
        'La operación falló, inetnet más tarde por favor.'
      );
      dispatchGetSamTypes({
        type: GET_SAM_TYPES_ERROR,
        payload: error.message,
      });
      return 'error';
    }
  };

  return { getSamTypes };
};

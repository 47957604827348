import { MultiSelectChangeEvent } from 'primereact/multiselect';
import { FunctionComponent, useEffect, useState } from 'react';
import { RiFileExcel2Line, RiCalculatorLine } from 'react-icons/ri';

import { ButtonComponent } from '../../../components/ButtonComponen';
import { InputComponent } from '../../../components/InputComponent';
import { MultiSelectComponent } from '../../../components/MultiSelectComponent';
import useApi from '../../../services/request.service';
import { PermitsList } from './../../../permitsList';
import { validatePermissions } from '../../../utils/utils';
import { RechargeCouterModal } from './rechargeCouterModal';

interface IFiltersComponent {
  columnList: any[];
  handlerColumn: any;
  handlerFilters: any;
  handlerExcelExport: any;
  columnSelected: any[];
  selectedSam: any;
  getSamData: any;
  setFilterGlobal: any;
  setDeviceFilter: any;
  setSelectedSam: any;
}

export const Filters = (props: IFiltersComponent) => {
  const {
    columnList,
    handlerColumn,
    handlerFilters,
    handlerExcelExport,
    columnSelected,
    selectedSam,
    getSamData,
    setFilterGlobal,
    setDeviceFilter,
    setSelectedSam
  } = props;

  //states
  const [columnsList, setColumnsList] = useState<any[]>([]);
  const [columnsSelected, setColumnSelected] = useState<any[]>(
    [] /* columnSelected */
  );

  const [deviceList, setDeviceList] = useState<any[]>([]);
  const [devicesSelected, setDevicesSelected] = useState<any[]>([]);

  const [operatorsList, setOperatorsList] = useState<any[]>([]);
  const [operatorsSelected, setOperatorsSelected] = useState<any[]>([]);

  const [projectsList, setProjectsList] = useState<any[]>([]);
  const [projectsSelected, setProjectsSelected] = useState<any[]>([]);

  const [showRechargeCounterModal, setShowRechargeCounterModal] =
    useState<boolean>(false);

  const [samIdField, setSamIdField] = useState<string>('');

  const [deviceIdField, setDeviceIdField] = useState<string>('');

  //hooks
  const { genericApiConfiguration } = useApi();

  useEffect(() => {
    setColumnSelected(columnSelected);

    setColumnsList(columnList);

    const getDevices = () => {
      genericApiConfiguration('/device-types', 'GET', {}, {}, {}).then(
        (response: any) => {
          if (response !== null) {
            setDeviceList(response.data.message);
          }
        }
      );
    };

    const getOperators = () => {
      genericApiConfiguration('/operators', 'GET', {}, {}, {}).then(
        (response: any) => {
          if (response !== null) {
            setOperatorsList(response.data.message);
          }
        }
      );
    };

    const getProjects = () => {
      genericApiConfiguration('/projects', 'GET', {}, {}, {}).then(
        (response: any) => {
          if (response !== null) {
            setProjectsList(response.data.message);
          }
        }
      );
    };

    getDevices();
    getOperators();
    getProjects();
    //* TODO: Mientras se pasa a BD el filtro que seleccionaro, se guarda todo el array en localStorage
    if (localStorage.getItem('selectedDevices') !== null) {
      setDevicesSelected(
        JSON.parse(localStorage.getItem('selectedDevices') || '')[0]
      );
    }
    if (localStorage.getItem('selectedOperators') !== null) {
      setOperatorsSelected(
        JSON.parse(localStorage.getItem('selectedOperators') || '')[0]
      );
    }
    if (localStorage.getItem('selectedProjects') !== null) {
      setProjectsSelected(
        JSON.parse(localStorage.getItem('selectedProjects') || '')[0]
      );
    }
  }, []);

  useEffect(() => {
    handlerColumn(columnsSelected);
  }, [columnsSelected]);

  /**
   * Mientras se pasa a BD el filtro que seleccionaro, se guarda todo el array en localStorage
   * @param e MultiSelectChangeEvent
   */

  function selectDevice(e: MultiSelectChangeEvent) {
    const selectedDevices = [e.value];
    localStorage.setItem('selectedDevices', JSON.stringify(selectedDevices));
    setDevicesSelected(e.value);
  }

  function selectOperators(e: MultiSelectChangeEvent) {
    const selectedOperators = [e.value];
    localStorage.setItem(
      'selectedOperators',
      JSON.stringify(selectedOperators)
    );
    setOperatorsSelected(e.value);
  }

  function selectProjects(e: MultiSelectChangeEvent) {
    const selectedProjects = [e.value];
    localStorage.setItem('selectedProjects', JSON.stringify(selectedProjects));
    setProjectsSelected(e.value);
  }

  const renderSelectDevices = () => {
    return (
      <div className="w-40">
        <MultiSelectComponent
          options={deviceList}
          optionLabel={'name'}
          placeholder="Dispositivos"
          value={devicesSelected}
          className="h-12"
          onChange={(e: MultiSelectChangeEvent) => selectDevice(e)}
          max={0}
          selectedItemsLabel="Seleccionar columnas"
        />
      </div>
    );
  };

  const renderSelectOperators = () => {
    return (
      <div className="w-60">
        <MultiSelectComponent
          options={operatorsList}
          optionLabel={'name'}
          placeholder="Operadores tecnológicos"
          value={operatorsSelected}
          className="h-12"
          onChange={(e: MultiSelectChangeEvent) => selectOperators(e)}
          max={2}
          selectedItemsLabel="Seleccionar columnas"
        />
      </div>
    );
  };

  const renderSelectProjects = () => {
    return (
      <div className="w-40">
        <MultiSelectComponent
          options={projectsList}
          optionLabel={'name'}
          placeholder="Proyectos"
          value={projectsSelected}
          className=" h-12"
          onChange={(e: MultiSelectChangeEvent) => selectProjects(e)}
          max={2}
          selectedItemsLabel="Seleccionar columnas"
        />
      </div>
    );
  };

  const renderFindSearch = () => {
    return (
      <div className="w-40">
        <InputComponent
          type={'text'}
          name={'find'}
          value={samIdField}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            {
              setSamIdField(e.target.value)
              setFilterGlobal(e.target.value)
            }
          }
          placeholder="Filtrar por SAM ID"
          classSpan={'p-input-icon-right'}
        />
      </div>
    );
  };

  const renderFindSearchDeviceId = () => {
    return (
      <div className="w-45">
        <InputComponent
          type={'text'}
          name={'find'}
          value={deviceIdField}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            {
              setDeviceIdField(e.target.value)
              setDeviceFilter(e.target.value)
            }
          }
          placeholder="Filtrar por Id de equipo"
          classSpan={'p-input-icon-right'}
        />
      </div>
    );
  };

  const renderSelectColumn = () => {
    return (
      <div>
        <MultiSelectComponent
          options={columnsList}
          optionLabel={'header'}
          placeholder="Seleccionar columnas"
          value={columnsSelected}
          className=" h-12"
          onChange={(e: MultiSelectChangeEvent) => setColumnSelected(e.value)}
          max={2}
          selectedItemsLabel="Seleccionar columnas"
        />
      </div>
    );
  };

  const generateExcel = () => {
    //Debido a que se repiten las cabeceras de los qwery params, no podemos usar un Json para pasarlos al axios, lo vamos a implementar directo en la url;
    let queryRequest = '';
    devicesSelected.forEach(element => {
      queryRequest = `${queryRequest}&deviceType=${element.name}`;
    });
    operatorsSelected.forEach(element => {
      queryRequest = `${queryRequest}&technologicalOperator=${element.name}`;
    });
    projectsSelected.forEach(element => {
      queryRequest = `${queryRequest}&project=${element.name}`;
    });
    if (samIdField !== '') {
      queryRequest = `${queryRequest}&samIds=${samIdField}`;
    }
    handlerExcelExport(queryRequest);
  };

  const renderExcel = () => {
    return validatePermissions(PermitsList.SAM_INVENTORY_EXPORT) ? (
      <div className="flex h-12 text-[50px] text-alternative_green content-center items-center cursor-pointer">
        <RiFileExcel2Line onClick={generateExcel} title='Exportar a Excel'/>
      </div>
    ) : (
      <></>
    );
  };

  const openRechargesCounterModal = () => {
    if (selectedSam !== undefined && selectedSam.length > 0) {
      setShowRechargeCounterModal(true);
    }
  };

  const closeRechargesCounterModal = () => {
    setShowRechargeCounterModal(false);
    getSamData();
    setSelectedSam([])
  };

  const renderCountersRecharge = () => {
    return (
      <div
        className={`flex h-12 text-[50px]  content-center items-center cursor-pointer ${
          selectedSam !== undefined && selectedSam.length > 0
            ? 'text-alternative_green'
            : 'text-grey cursor-default'
        }`}>
        <RiCalculatorLine onClick={openRechargesCounterModal} title='Agregar contadores'/>
      </div>
    );
  };

  const sendData = () => {
    //Debido a que se repiten las cabeceras de los qwery params, no podemos usar un Json para pasarlos al axios, lo vamos a implementar directo en la url;
    let queryRequest = '';
    devicesSelected.forEach(element => {
      queryRequest = `${queryRequest}&deviceType=${element.name}`;
    });
    operatorsSelected.forEach(element => {
      queryRequest = `${queryRequest}&technologicalOperator=${element.name}`;
    });
    projectsSelected.forEach(element => {
      queryRequest = `${queryRequest}&project=${element.name}`;
    });
    if (samIdField !== '') {
      queryRequest = `${queryRequest}&samIds=${samIdField}`;
    }
    handlerFilters(queryRequest);
  };

  const renderButtonSendData = () => {
    return (
      <div className="w-44 h-12">
        <ButtonComponent
          label="Enviar"
          onClick={sendData}
          className={'h-full'}
        />
      </div>
    );
  };

  return (
    <>
      <div className="w-full h-auto flex justify-between pb-3">
        <div className="flex gap-2">
          {renderSelectDevices()}
          {renderSelectOperators()}
          {renderSelectProjects()}
          {renderFindSearch()}
          {renderFindSearchDeviceId()}
          {renderButtonSendData()}
        </div>
        <div className="flex gap-2">
          {renderCountersRecharge()}
          {renderExcel()}
          {renderSelectColumn()}
        </div>
      </div>
      {showRechargeCounterModal ? (
        <RechargeCouterModal
          show={showRechargeCounterModal}
          closeMenu={setShowRechargeCounterModal}
          selectedSam={selectedSam}
          handlerUpdateData={closeRechargesCounterModal}
          setSelectedSam = {setSelectedSam}
        />
      ) : (
        false
      )}
    </>
  );
};

import {
  IProject,
  IServiceMenuComponent,
} from '@/interfaces/interfaceCatalogs';
import { useFormik } from 'formik';
import { InputSwitchChangeEvent } from 'primereact/inputswitch';
import { classNames } from 'primereact/utils';
import { useEffect, useState } from 'react';

import { ButtonComponent } from '../../../../components/ButtonComponen';
import { InputComponent } from '../../../../components/InputComponent';
import { SwitchComponent } from '../../../../components/SwitchComponent';
import { usegetProjectsCatalogs } from '../../../../hooks/catalogs/useProjectsCatalog';
import { useTransportCompaniesCatalogs } from '../../../../hooks/catalogs/useTransportCompaniesCatalogs';
import { useSamTypes } from '../../../../hooks/inventory/useSamTypes';
import { createObjectAsInterface } from '../../../../utils/utils';
import { ProjectValidations } from './validations_form';

export const ProjectForm = (props: IServiceMenuComponent) => {
  const { dataRow, selectMenu, updateTable } = props;

  /**
   * Crea un objeto vacio para usarse en el initialValues de formik con la interface del formulario
   */
  const objAsEcnomicNumberInterface: IProject = {
    projectId: '',
    name: '',
    status: false,
  };

  /**
   * Estado que guarda el objeto creado de acuerdo a la interface del formulario
   */
  const [inputs, setInputs] = useState<IProject>(objAsEcnomicNumberInterface);

  const [transports, setTransports] = useState<any>();
  const [samTypes, setSamTypes] = useState<any>();

  /**
   * Texto del boton.
   * {string} Puede ser Agregar | Actualizar
   */
  const [buttonLabel, setButtonLabel] = useState<string>('Agregar');

  //Hooks

  const { getTransportCompaniesCatalog } = useTransportCompaniesCatalogs();
  const { getSamTypes } = useSamTypes();
  const { postProjectsCatalog, putProjectsCatalog } = usegetProjectsCatalogs();

  /**
   * Se piden los catalogos necesarios para llenar los select
   */
  useEffect(() => {
    const getData = async () => {
      let response = await getTransportCompaniesCatalog();
      if (response.status === 200) {
        setTransports(response.data.message);
      }
      let samResponse = await getSamTypes();
      if (samResponse.status === 200) {
        setSamTypes(samResponse.data);
      }
    };

    getData();
  }, []);

  /**
   * Si es una edicion de un item, se toma el objeto seleccionado y se le da el formato para que se usen los datos en el formik
   */
  useEffect(() => {
    if (dataRow !== undefined) {
      setButtonLabel('Actualizar');
      let obj = createObjectAsInterface(dataRow, objAsEcnomicNumberInterface);
      setInputs(obj);
    }
  }, [dataRow]);

  /**
   * Se manda la peticion al back
   */
  const handleSubmit = async (values: any) => {
    let response: any = {};
    values['userId'] = localStorage.getItem('userId');

    if (buttonLabel === 'Actualizar') {
      let id = dataRow.id;
      values['id'] = id;
      response = await putProjectsCatalog(values);
    } else if (buttonLabel === 'Agregar') {
      response = await postProjectsCatalog(values);
    }
    if (response.status === 200) {
      updateTable(response.status);
    }
  };

  const formik = useFormik({
    initialValues: inputs,
    enableReinitialize: true,
    validationSchema: ProjectValidations,
    onSubmit: data => {
      handleSubmit(data);
    },
  });

  const isFormFieldValid = (name: 'name' | 'status') =>
    !!(formik.touched[name] && formik.errors[name]);

  const getFormErrorMessage = (name: 'name' | 'status') => {
    return (
      isFormFieldValid(name) && (
        <small className="p-error">{formik.errors[name]}</small>
      )
    );
  };

  /* 
      | 'transportCompanyId'
      | 'samTypeId' */
  const renderMenu = (): any => {
    return (
      <form
        className="h-full w-full flex flex-col pt-5 px-6"
        onSubmit={formik.handleSubmit}>

        <div className="flex flex-col">
          <InputComponent
            label={'Nombre'}
            type={'text'}
            name={'name'}
            onChange={formik.handleChange}
            value={formik?.values?.['name']}
            placeholder={'Nombre'}
            className={classNames({
              'p-invalid': isFormFieldValid('name'),
            })}
          />
          {getFormErrorMessage('name')}
        </div>

        <div className="flex flex-col">
          <SwitchComponent
            checked={formik?.values?.['status'] || 'false'}
            onChange={(e: InputSwitchChangeEvent) => {
              formik.setFieldValue('status', e.value);
            }}
            label={'Estatus'}
            className={classNames({
              'p-invalid': isFormFieldValid('status'),
            })}
          />
          {getFormErrorMessage('status')}
        </div>

        <ButtonComponent className="mt-10" label={buttonLabel} type="submit" />
      </form>
    );
  };

  return (
    <div className="flex w-full h-full flex-col items-center bg-white  shadow-[-6px_-1px_5px_#00000024] z-10 right-0">
      <div className="flex w-full h-full overflow-y-auto">{renderMenu()}</div>
    </div>
  );
};

import * as React from 'react';
import { Route } from 'react-router-dom';

import { Catalogs } from '../pages/Catalogs';
import { Inventory } from '../pages/Inventary/Index';
import { Roles } from './../pages/Roles';
import { UserManager } from './../pages/UsersManager';
import { RedirectComponent } from './../pages/Redirect';
import { HistorySam } from 'src/pages/HistorySam/Index';

interface IRouterProps {
  path: string;
  name: string;
  element: React.ReactNode;
  component?: JSX.Element;
}

const routerBrowser: IRouterProps[] = [
  { path: '/', name: 'inventario', element: <Inventory /> },
  { path: '/inventario', name: 'inventario', element: <Inventory /> },
  { path: '/history-sam', name: 'Historial de sam', element: <HistorySam /> },
  { path: '/catalogos', name: 'catalog', element: <Catalogs /> },
  { path: '/administrar-usuarios', name: 'usuarios', element: <UserManager /> },
  { path: '/roles', name: 'roles', element: <Roles /> },
  { path: '/denegado', name: 'denegado', element: <RedirectComponent /> },
];

const RouterConfig = () => {
  return (
    <>
      {routerBrowser.map(route => (
        <Route key={route.name} path={route.path} element={route.element} />
      ))}
    </>
  );
};

export default RouterConfig;

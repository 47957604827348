import {
  PUT_EDIT_SAM,
  PUT_EDIT_SAM_ERROR,
  PUT_EDIT_SAM_SUCCESS,
} from '../../../constants/constantsRed';

export const putSamRed = (state: any = false, actions: any) => {
  switch (actions.type) {
    case PUT_EDIT_SAM:
      return {
        ...state,
        loading: true,
      };
    case PUT_EDIT_SAM_ERROR:
      return {
        data: null,
        loading: false,
        error: null,
      };
    case PUT_EDIT_SAM_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        data: actions.payload,
      };
    default:
      return state;
  }
};

import { InterfaceInput } from '../../../../interfaces/interfaces';
import { useFormik } from 'formik';
import { classNames } from 'primereact/utils';
import { useEffect, useState } from 'react';

import { BasicSelectComponent } from '../../../../components/BasicSelectComponentComponent';
import { ButtonComponent } from '../../../../components/ButtonComponen';
import { InputComponent } from '../../../../components/InputComponent';
import { useCatalogs } from '../../../../hooks/inventory/useCatalogs';
import { useEditSam } from '../../../../hooks/inventory/useEditSam';
import {
  createObjectAsInterface,
  eliminarNull,
  getCurrentPosition,
} from '../../../../utils/utils';
import { useDeviceTypesCatalogs } from '../../../../hooks/catalogs/useDeviceTypesCatalogs';
import { IEditSam, EditSamInterface } from '@/interfaces/interfaceSamData';

//Constants

const dropdownClassname =
  'w-full rounded-md border border-gray-600 text-gray-400 border-opacity-20 px-1.5 p-inputtext-sm mt-2 mb-7';

export const EditSamMenu = (props: IEditSam) => {
  let data = props.row;
  const { setOpenRightMenu, updateTable } = props;
  // states

  const [showMessage, setShowMessage] = useState(false);
  const [deviceTypes, setDeviceTypes] = useState(false);

  const [operatorsList, setOperatorsList] = useState<any>([]);

  const { putEditSam } = useEditSam();

  const { getOperatorsCatalog } = useCatalogs();
  const { getDeviceTypesCatalog } = useDeviceTypesCatalogs();

  const objAsEditSamInterface: EditSamInterface = {
    SamId: '',
    IdSocket: '',
    Address: '',
    NumberDevice: '',
    CarPlates: '',
    EconomicNumber: '',
    RouteName: '',
    Observations: '',
    Station: '',
    moduloPc: '',
    TechnologicalOperator: '',
    WhiteList: '',
    Issued: '',
  };

  const [inputs, setInputs] = useState<EditSamInterface>(objAsEditSamInterface);

  const close = () => {
    setOpenRightMenu(false);
  };

  useEffect(() => {
    async function getDevices() {
      let response = await getDeviceTypesCatalog();
      if (response?.['status'] === 200) {
        setDeviceTypes(response.data.payload);
      }
    }
    getDevices();
  }, [props]);

  useEffect(() => {
    if (data !== undefined && data !== null) {
      let objetoFiltrado: any = createObjectAsInterface(
        data,
        objAsEditSamInterface
      );

      let operatorFound: any = [];

      const getCatalogs = async () => {
        const res = await getOperatorsCatalog();
        if (res.status === 200) {
          operatorFound = res.data.message.find(
            (element: any) => data['TechnologicalOperator'] === element.name
          );
          objetoFiltrado.TechnologicalOperator = operatorFound.id;
          setInputs(objetoFiltrado);

          setOperatorsList(res.data.message);
        }
      };

      getCatalogs();
    }
  }, [props]);

  const handleSubmit = async (values: any) => {
    if (
      !isNaN(parseFloat(values.TechnologicalOperator)) &&
      isFinite(values.TechnologicalOperator)
    ) {
      values.technologicalOperatorId = Number(values.TechnologicalOperator);
      delete values.TechnologicalOperator;
    }

    const dataToSend = eliminarNull(values);

    let coords = await getCurrentPosition();

    const headers = {
      locationGPS: `${coords.latitude}, ${coords.latitude}`,
      appId: 'web',
    };

    let response = await putEditSam(dataToSend, headers);
    updateTable();
  };

  const formik = useFormik({
    initialValues: inputs,
    enableReinitialize: true,
    validate: (data: any) => {
      /* const requiredFields = [
        'IdSocket',
        'Address',
        'NumberDevice',
        'CarPlates',
        'EconomicNumber',
        'RouteName',
        'Observations',
        'Station',
        'Project',
        'TechnologicalOperator',
      ];

      if (typeof data['groupId'] !== 'object') {
        errors['groupId'] = 'Campo requerido';
      } */
      const errors: any = {};
      return errors;
    },
    onSubmit: data => {
      //setFormData(data);
      setShowMessage(true);
      handleSubmit(data);
    },
  });

  const isFormFieldValid = (
    name:
      | 'IdSocket'
      | 'Address'
      | 'NumberDevice'
      | 'CarPlates'
      | 'EconomicNumber'
      | 'RouteName'
      | 'Observations'
      | 'Station'
      | 'Project'
      | 'TechnologicalOperator'
  ) => !!(formik.touched[name] && formik.errors[name]);
  const getFormErrorMessage = (
    name:
      | 'IdSocket'
      | 'Address'
      | 'NumberDevice'
      | 'CarPlates'
      | 'EconomicNumber'
      | 'RouteName'
      | 'Observations'
      | 'Station'
      | 'Project'
      | 'TechnologicalOperator'
  ) => {
    return (
      isFormFieldValid(name) && (
        <small className="p-error">{formik.errors[name]}</small>
      )
    );
  };

  /**
   *
   * Dinamic components
   *
   */

  interface IInputForm {
    typedName:
      | 'IdSocket'
      | 'Address'
      | 'NumberDevice'
      | 'CarPlates'
      | 'EconomicNumber'
      | 'RouteName'
      | 'Observations'
      | 'Station'
      | 'Project'
      | 'TechnologicalOperator';
  }

  type IRenderInput = InterfaceInput & IInputForm;

  const renderInput = (props: IRenderInput) => {
    return (
      <div className="flex flex-col mt-3">
        <InputComponent
          label={props.label}
          type={props.type}
          name={props.name}
          onChange={formik.handleChange}
          value={formik?.values?.[props.typedName] || ''}
          //className="rounded-sm border border-gray-800 border-opacity-20 px-1.5 py-0.5 mt-2 mb-7 w-full"
          className={classNames({
            'p-invalid': isFormFieldValid(props.typedName),
          })}
          placeholder={props.placeholder}
        />
        {getFormErrorMessage(props.typedName)}
      </div>
    );
  };

  const renderVrtFields = () => {
    return (
      <>
        {renderInput({
          label: 'Número de VRT',
          type: 'text',
          name: 'NumberDevice',
          typedName: 'NumberDevice',
          placeholder: 'Operador',
        })}

        <BasicSelectComponent
          label={'Operador tecnológico'}
          options={operatorsList}
          optionLabel={'name'}
          name="TechnologicalOperator"
          optionValue={'id'}
          onChange={formik.handleChange}
          value={formik?.values?.['TechnologicalOperator'] || ''}
        />

        {renderInput({
          label: 'Proyecto',
          type: 'text',
          name: 'Project',
          typedName: 'Project',
          placeholder: 'Proyecto',
        })}

        {renderInput({
          label: 'Estación',
          type: 'text',
          name: 'Station',
          typedName: 'Station',
          placeholder: 'Estación',
        })}

        {renderInput({
          label: 'Observaciones',
          type: 'text',
          name: 'Observations',
          typedName: 'Observations',
          placeholder: 'Observaciones',
        })}
      </>
    );
  };

  const renderCetFields = () => {
    return (
      <>
        {renderInput({
          label: 'Número de CET',
          type: 'text',
          name: 'NumberDevice',
          typedName: 'NumberDevice',
          placeholder: 'Operador',
        })}

        <BasicSelectComponent
          label={'Operador tecnológico'}
          options={operatorsList}
          optionLabel={'name'}
          name="TechnologicalOperator"
          optionValue={'id'}
          onChange={formik.handleChange}
          value={formik?.values?.['TechnologicalOperator'] || ''}
        />

        {renderInput({
          label: 'Corredor',
          type: 'text',
          name: 'RouteName',
          typedName: 'RouteName',
          placeholder: 'Corredor',
        })}

        {renderInput({
          label: 'Vehículo',
          type: 'text',
          name: 'EconomicNumber',
          typedName: 'EconomicNumber',
          placeholder: 'Vehículo',
        })}

        {renderInput({
          label: 'Placa',
          type: 'text',
          name: 'CarPlates',
          typedName: 'CarPlates',
          placeholder: 'Placa',
        })}
        {renderInput({
          label: 'Observaciones',
          type: 'text',
          name: 'Observations',
          typedName: 'Observations',
          placeholder: 'Observaciones',
        })}
      </>
    );
  };

  const renderPosFields = () => {
    return (
      <>
        {renderInput({
          label: 'Número de Pos',
          type: 'text',
          name: 'NumberDevice',
          typedName: 'NumberDevice',
          placeholder: 'Operador',
        })}

        <BasicSelectComponent
          label={'Operador tecnológico'}
          options={operatorsList}
          optionLabel={'name'}
          name="TechnologicalOperator"
          optionValue={'id'}
          onChange={formik.handleChange}
          value={formik?.values?.['TechnologicalOperator'] || ''}
        />

        {renderInput({
          label: 'Proyecto',
          type: 'text',
          name: 'Project',
          typedName: 'Project',
          placeholder: 'Proyecto',
        })}

        {renderInput({
          label: 'Dirección',
          type: 'text',
          name: 'Address',
          typedName: 'Address',
          placeholder: 'Dirección',
        })}

        {renderInput({
          label: 'Observaciones',
          type: 'text',
          name: 'Observations',
          typedName: 'Observations',
          placeholder: 'Observaciones',
        })}
      </>
    );
  };

  const renderCauFields = () => {
    return (
      <>
        {renderInput({
          label: 'Número de CAU',
          type: 'text',
          name: 'NumberDevice',
          typedName: 'NumberDevice',
          placeholder: 'Operador',
        })}

        <BasicSelectComponent
          label={'Operador tecnológico'}
          options={operatorsList}
          optionLabel={'name'}
          name="TechnologicalOperator"
          optionValue={'id'}
          onChange={formik.handleChange}
          value={formik?.values?.['TechnologicalOperator'] || ''}
        />

        {renderInput({
          label: 'Proyecto',
          type: 'text',
          name: 'Project',
          typedName: 'Project',
          placeholder: 'Proyecto',
        })}

        {renderInput({
          label: 'Dirección',
          type: 'text',
          name: 'Address',
          typedName: 'Address',
          placeholder: 'Dirección',
        })}

        {renderInput({
          label: 'Observaciones',
          type: 'text',
          name: 'Observations',
          typedName: 'Observations',
          placeholder: 'Observaciones',
        })}
      </>
    );
  };

  const renderGranjaFields = () => {
    return (
      <>
        {renderInput({
          label: 'Número de aplicación movil',
          type: 'text',
          name: 'NumberDevice',
          typedName: 'NumberDevice',
          placeholder: 'Operador',
        })}

        <BasicSelectComponent
          label={'Operador tecnológico'}
          options={operatorsList}
          optionLabel={'name'}
          name="TechnologicalOperator"
          optionValue={'id'}
          onChange={formik.handleChange}
          value={formik?.values?.['TechnologicalOperator'] || ''}
        />

        {renderInput({
          label: 'Proyecto',
          type: 'text',
          name: 'Project',
          typedName: 'Project',
          placeholder: 'Proyecto',
        })}

        {renderInput({
          label: 'Número de socket',
          type: 'number',
          name: 'IdSocket',
          typedName: 'IdSocket',
          placeholder: 'Número de socket',
        })}

        {renderInput({
          label: 'Dirección',
          type: 'text',
          name: 'Address',
          typedName: 'Address',
          placeholder: 'Dirección',
        })}

        {renderInput({
          label: 'Observaciones',
          type: 'text',
          name: 'Observations',
          typedName: 'Observations',
          placeholder: 'Observaciones',
        })}
      </>
    );
  };

  /*
   *Show dinamic fields by device type
   */
  const showDinamicFields = () => {
    const device = props.row.DeviceTypeEnum;
    if (device === 1) {
      return <>{renderVrtFields()}</>;
    } else if (device === 4) {
      return <>{renderCetFields()}</>;
    } else if (device === 3 || device === 5 || device === 7) {
      return <>{renderPosFields()}</>;
    } else if (device === 6) {
      return <>{renderCauFields()}</>;
    } else if (device === 8) {
      return <>{renderGranjaFields()}</>;
    }
  };

  return (
    <form
      className="flex w-full h-full mx-5 mt-7 flex-col content-center border-b border-gray-200 p-3"
      onSubmit={formik.handleSubmit}>
      {/*  
      {renderDropdown({
        label: 'Tipo de SAM',
        name: 'samType',
        options: SamTypes,
        optionLabel: 'name',
        className: dropdownClassname,
        placeholder: 'Selecciona un tipo de SAM',
        value: 'samType',
      })}
      })} */}
      <hr />

      {showDinamicFields()}

      <div className="flex justify-between my-5">
        <ButtonComponent
          name="Cancelar"
          label="Cancelar"
          className="  !bg-deleteButton !text-white !hover:bg-deleteButton border-none rounded-3xl text-white mt-8 px-6 py-2"
          type="button"
          onClick={close}
        />
        <ButtonComponent
          name="Actualizar"
          label="Actualizar"
          className=" bg-official_green !hover:bg-official_green border-none rounded-3xl text-white mt-8 px-6 py-2"
          type="submit"
        />
      </div>
    </form>
  );
};

import { createContext, Dispatch, useReducer, useState } from 'react';

import { deleteDeviceTypesCatalogRed } from '../business/Reducers/catalogs/deviceTypes/deleteDeviceTypesCatalogRed';
import { getDeviceTypesCatalogRed } from '../business/Reducers/catalogs/deviceTypes/getDeviceTypesCatalogRed';
import { postDeviceTypesCatalogRed } from '../business/Reducers/catalogs/deviceTypes/postDeviceTypesCatalogRed';
import { putDeviceTypesCatalogRed } from '../business/Reducers/catalogs/deviceTypes/putDeviceTypesCatalogRed';
import { getEconomicNumbersCatalogRed } from '../business/Reducers/catalogs/economicNumbers/getEconomicNumbersCatalogRed';
import { getExportCatalogRed } from '../business/Reducers/catalogs/getExportCatalogRed';
import { getPcModulesCatalogRed } from '../business/Reducers/catalogs/getPcModulesCatalogRed';
import { getProjectsCatalogRed } from '../business/Reducers/catalogs/projects/getProjectsCatalogRed';
import { postProjectsCatalogRed } from '../business/Reducers/catalogs/projects/postProjectsCatalogRed';
import { putProjectsCatalogRed } from '../business/Reducers/catalogs/projects/putProjectsCatalogRed';
import { deleteProjectsCatalogRed } from '../business/Reducers/catalogs/projects/deleteProjectsCatalogRed';
import { getLocationsCatalogRed } from '../business/Reducers/catalogs/locations/getLocationsCatalogRed';
import { getOperatorsCatalogRed } from '../business/Reducers/catalogs/operators/getOperatorsCatalogRed';
import { deletePosCatalogRed } from '../business/Reducers/catalogs/points_of_sales/deletePointsOfSalesCatalogRed';
import { getPointsOfSalesCatalogRed } from '../business/Reducers/catalogs/points_of_sales/getPointsOfSalesCatalogRed';
import { postPosCatalogRed } from '../business/Reducers/catalogs/points_of_sales/postPointsOfSalesCatalogRed';
import { putPosCatalogRed } from '../business/Reducers/catalogs/points_of_sales/putPointsOfSalesCatalogRed';
import { deleteRoutesCatalogRed } from '../business/Reducers/catalogs/routes/deleteRoutesCatalogRed';
import { getRoutesCatalogRed } from '../business/Reducers/catalogs/routes/getRoutesCatalogRed';
import { postRoutesCatalogRed } from '../business/Reducers/catalogs/routes/postRoutesCatalogRed';
import { putRoutesCatalogRed } from '../business/Reducers/catalogs/routes/putRoutesCatalogRed';
import { deleteStationsCatalogRed } from '../business/Reducers/catalogs/stations/deleteStationsCatalogRed';
import { getStationsCatalogRed } from '../business/Reducers/catalogs/stations/getStationsCatalogRed';
import { postStationsCatalogRed } from '../business/Reducers/catalogs/stations/postStationsCatalogRed';
import { putStationsCatalogRed } from '../business/Reducers/catalogs/stations/putStationsCatalogRed';
import { deleteTransportCompaniesCatalogRed } from '../business/Reducers/catalogs/transport_companies/deleteTransportCompaniesCatalogRed';
import { getTransportCompaniesCatalogRed } from '../business/Reducers/catalogs/transport_companies/getTransportCompaniesCatalogRed';
import { postTransportCompaniesCatalogRed } from '../business/Reducers/catalogs/transport_companies/postTransportCompaniesCatalogRed';
import { putTransportCompaniesCatalogRed } from '../business/Reducers/catalogs/transport_companies/putTransportCompaniesCatalogRed';
import { loadingRed } from '../business/Reducers/loading/loadingRed';
import { patchSamRed } from '../business/Reducers/sam/patchSamRed';
import { putSamRed } from '../business/Reducers/sam/putSamRed';
import { getSettingsRed } from '../business/Reducers/settings/getSettingsRed';
import { globalInitialState } from '../business/States/initialStateGlobal';
import { deleteEconomicNumbersCatalogRed } from './../business/Reducers/catalogs/economicNumbers/deleteEconomicNumbersCatalogRed';
import { postEconomicNumbersCatalogRed } from './../business/Reducers/catalogs/economicNumbers/postEconomicNumbersCatalogRed';
import { putEconomicNumbersCatalogRed } from './../business/Reducers/catalogs/economicNumbers/putEconomicNumbersCatalogRed';
import { deleteLocationsCatalogRed } from './../business/Reducers/catalogs/locations/deleteLocationsCatalogRed';
import { postLocationsCatalogRed } from './../business/Reducers/catalogs/locations/postLocationsCatalogRed';
import { putLocationsCatalogRed } from './../business/Reducers/catalogs/locations/putLocationsCatalogRed';
import { deleteOperatorsCatalogRed } from './../business/Reducers/catalogs/operators/deleteOperatorsCatalogRed';
import { postOperatorsCatalogRed } from './../business/Reducers/catalogs/operators/postOperatorsCatalogRed';
import { putOperatorsCatalogRed } from './../business/Reducers/catalogs/operators/putOperatorsCatalogRed';
import { postExportDeviceTypesCatalogRed } from './../business/Reducers/catalogs/postExportDeviceTypesCatalogRed';
import { getSamByIdRed } from './../business/Reducers/sam/getSamByIdRed';
import { getSamTypesRed } from './../business/Reducers/sam/getSamTypesRed';
import { deleteUserManagerRed } from './../business/Reducers/userManager/deleteUserManagerRed';
import { getRolesRed } from './../business/Reducers/userManager/getRolesRed';
import { patchRoleRed } from './../business/Reducers/userManager/patchRoleRed';
import { postRoleRed } from './../business/Reducers/userManager/postRoleRed';
import { deleteRoleRed } from './../business/Reducers/userManager/deleteRolesRed';
import { getUserManagerRed } from './../business/Reducers/userManager/getUserManagerRed';
import { patchUserManagerRed } from './../business/Reducers/userManager/patchRolesRed';
import { postUserManagerRed } from './../business/Reducers/userManager/postUserManagerRed';
import { useGetInventoryState, useGetInvetoryToExcelState } from './../business/States/inventory/inventoryStates';

interface IProviderState {
  data: any;
  loading: boolean;
  error: any;
}

interface IProvider {
  loadingState: any;
  dispatchLoading: (data: any) => void;

  openRightMenu: boolean;
  setOpenRightMenu: (any: boolean) => void;

  getSettingsState: IProviderState;
  dispatchSettings: (data: any) => void;

  /**
   * Sam Zone
   */

  patchEditSamState: IProviderState;
  dispatchPatchEditSam: (data: any) => void;

  putEditSamState: IProviderState;
  dispatchPutEditSam: (data: any) => void;

  getSamByIdState: IProviderState;
  dispatchGetSamById: (data: any) => void;

  getSamTypesState: IProviderState;
  dispatchGetSamTypes: (data: any) => void;

  putRechargeCounterState: IProviderState;
  dispatchPutRechargeCounter: (data: any) => void;

  /**
   * Users Zone
   */

  getUserManagerState: IProviderState;
  dispatchGetUserManager: (data: any) => void;

  postUserManagerState: IProviderState;
  dispatchPostUserManager: (data: any) => void;

  deleteUserManagerState: IProviderState;
  dispatchDeleteUserManager: (data: any) => void;

  patchUserManagerState: IProviderState;
  dispatchPatchUserManager: (data: any) => void;

  //Roles

  getRolesState: IProviderState;
  dispatchGetRoles: (data: any) => void;

  postRoleState: IProviderState;
  dispatchPostRole: (data: any) => void;

  patchRoleState: IProviderState;
  dispatchPatchRole: (data: any) => void;

  deleteRoleState: IProviderState;
  dispatchDeleteRole: (data: any) => void;

  /**
   * Catalogs Zone
   */

  // Operators

  getOperatorsCatalogState: IProviderState;
  dispatchGetOperatorsCatalog: (data: any) => void;

  postOperatorsCatalogState: IProviderState;
  dispatchPostOperatorsCatalog: (data: any) => void;

  putOperatorsCatalogState: IProviderState;
  dispatchPutOperatorsCatalog: (data: any) => void;

  deleteOperatorsCatalogState: IProviderState;
  dispatchDeleteOperatorsCatalog: (data: any) => void;

  //locations

  getLocationCatalogState: IProviderState;
  dispatchGetLocationCatalog: (data: any) => void;

  postLocationsCatalogState: IProviderState;
  dispatchPostLocationsCatalog: (data: any) => void;

  putLocationsCatalogState: IProviderState;
  dispatchPutLocationsCatalog: (data: any) => void;

  deleteLocationsCatalogState: IProviderState;
  dispatchDeleteLocationsCatalog: (data: any) => void;

  //Device types

  getDeviceTypesCatalogState: IProviderState;
  dispatchGetDeviceTypesCatalog: (data: any) => void;

  postExportDeviceTypesCatalogState: IProviderState;
  dispatchPostExportDeviceTypesCatalog: (data: any) => void;

  postDeviceTypesCatalogState: IProviderState;
  dispatchPostDeviceTypesCatalog: (data: any) => void;

  putDeviceTypesCatalogState: IProviderState;
  dispatchPutDeviceTypesCatalog: (data: any) => void;

  deleteDeviceTypesCatalogState: IProviderState;
  dispatchDeleteDeviceTypesCatalog: (data: any) => void;

  // Economic numbers

  getEconomicNumbersCatalogState: IProviderState;
  dispatchGetEconomicNumbersCatalog: (data: any) => void;

  postEconomicNumbersCatalogState: IProviderState;
  dispatchPostEconomicNumbersCatalog: (data: any) => void;

  putEconomicNumbersCatalogState: IProviderState;
  dispatchPutEconomicNumbersCatalog: (data: any) => void;

  deleteEconomicNumbersCatalogState: IProviderState;
  dispatchDeleteEconomicNumbersCatalog: (data: any) => void;

  getPcModulesCatalogState: IProviderState;
  dispatchGetPcModulesCatalog: (data: any) => void;

  // Point of sales

  getPointsOfSalesCatalogState: IProviderState;
  dispatchGetPointsOfSalesCatalog: (data: any) => void;

  postPosCatalogState: IProviderState;
  dispatchPostPosCatalog: (data: any) => void;

  putPosCatalogState: IProviderState;
  dispatchPutPosCatalog: (data: any) => void;

  deletePosCatalogState: IProviderState;
  dispatchDeletePosCatalog: (data: any) => void;

  //Projects

  getProjectsCatalogState: IProviderState;
  dispatchGetProjectsCatalog: (data: any) => void;

  postProjectsCatalogState: IProviderState;
  dispatchPostProjectsCatalog: (data: any) => void;

  putProjectsCatalogState: IProviderState;
  dispatchPutProjectsCatalog: (data: any) => void;

  deleteProjectsCatalogState: IProviderState;
  dispatchDeleteProjectsCatalog: (data: any) => void;

  //Routes

  getRoutesCatalogState: IProviderState;
  dispatchGetRoutesCatalog: (data: any) => void;

  postRoutesCatalogState: IProviderState;
  dispatchPostRoutesCatalog: (data: any) => void;

  putRoutesCatalogState: IProviderState;
  dispatchPutRoutesCatalog: (data: any) => void;

  deleteRoutesCatalogState: IProviderState;
  dispatchDeleteRoutesCatalog: (data: any) => void;

  //Stations

  getStationsCatalogState: IProviderState;
  dispatchGetStationsCatalog: (data: any) => void;

  postStationsCatalogState: IProviderState;
  dispatchPostStationsCatalog: (data: any) => void;

  putStationsCatalogState: IProviderState;
  dispatchPutStationsCatalog: (data: any) => void;

  deleteStationsCatalogState: IProviderState;
  dispatchDeleteStationsCatalog: (data: any) => void;

  //Transport companies

  getTransportCompaniesCatalogState: IProviderState;
  dispatchGetTransportCompaniesCatalog: (data: any) => void;

  postTransportCompaniesCatalogState: IProviderState;
  dispatchPostTransportCompaniesCatalog: (data: any) => void;

  putTransportCompaniesCatalogState: IProviderState;
  dispatchPutTransportCompaniesCatalog: (data: any) => void;

  deleteTransportCompaniesCatalogState: IProviderState;
  dispatchDeleteTransportCompaniesCatalog: (data: any) => void;

  getExportCatalogState: IProviderState;
  dispatchGetExportCatalog: (data: any) => void;

  inventoryStateContext: { state: any; dispatch: Dispatch<any>; }
  
  inventoryToExcelStateContext: {state: any; dispatch: Dispatch<any>;}

}

export const StoreContext = createContext<IProvider>({} as IProvider);

export const StoreProvider = (props: any) => {
  const [loadingState, dispatchLoading] = useReducer(loadingRed, false);
  const [openRightMenu, setOpenRightMenu] = useState(false);

  const [getSettingsState, dispatchSettings] = useReducer(
    getSettingsRed,
    globalInitialState
  );

  // Sam Zone

  const [patchEditSamState, dispatchPatchEditSam] = useReducer(
    patchSamRed,
    globalInitialState
  );

  const [putEditSamState, dispatchPutEditSam] = useReducer(
    putSamRed,
    globalInitialState
  );

  const [getSamByIdState, dispatchGetSamById] = useReducer(
    getSamByIdRed,
    globalInitialState
  );

  const [getSamTypesState, dispatchGetSamTypes] = useReducer(
    getSamTypesRed,
    globalInitialState
  );

  const [putRechargeCounterState, dispatchPutRechargeCounter] = useReducer(
    putSamRed,
    globalInitialState
  );

  //--End Sam Zone

  // Users zone

  const [getUserManagerState, dispatchGetUserManager] = useReducer(
    getUserManagerRed,
    globalInitialState
  );

  const [deleteUserManagerState, dispatchDeleteUserManager] = useReducer(
    deleteUserManagerRed,
    globalInitialState
  );

  const [patchUserManagerState, dispatchPatchUserManager] = useReducer(
    patchUserManagerRed,
    globalInitialState
  );

  const [postUserManagerState, dispatchPostUserManager] = useReducer(
    postUserManagerRed,
    globalInitialState
  );

  const [getRolesState, dispatchGetRoles] = useReducer(
    getRolesRed,
    globalInitialState
  );

  const [postRoleState, dispatchPostRole] = useReducer(
    postRoleRed,
    globalInitialState
  );

  const [patchRoleState, dispatchPatchRole] = useReducer(
    patchRoleRed,
    globalInitialState
  );

  const [deleteRoleState, dispatchDeleteRole] = useReducer(
    deleteRoleRed,
    globalInitialState
  );

  //--End Users zone

  // Catalogs zone

  // Operators

  const [getOperatorsCatalogState, dispatchGetOperatorsCatalog] = useReducer(
    getOperatorsCatalogRed,
    globalInitialState
  );

  const [postOperatorsCatalogState, dispatchPostOperatorsCatalog] = useReducer(
    postOperatorsCatalogRed,
    globalInitialState
  );

  const [putOperatorsCatalogState, dispatchPutOperatorsCatalog] = useReducer(
    putOperatorsCatalogRed,
    globalInitialState
  );

  const [deleteOperatorsCatalogState, dispatchDeleteOperatorsCatalog] =
    useReducer(deleteOperatorsCatalogRed, globalInitialState);

  //Locations

  const [getLocationCatalogState, dispatchGetLocationCatalog] = useReducer(
    getLocationsCatalogRed,
    globalInitialState
  );

  const [postLocationsCatalogState, dispatchPostLocationsCatalog] = useReducer(
    postLocationsCatalogRed,
    globalInitialState
  );

  const [putLocationsCatalogState, dispatchPutLocationsCatalog] = useReducer(
    putLocationsCatalogRed,
    globalInitialState
  );

  const [deleteLocationsCatalogState, dispatchDeleteLocationsCatalog] =
    useReducer(deleteLocationsCatalogRed, globalInitialState);

  // Device types

  const [getDeviceTypesCatalogState, dispatchGetDeviceTypesCatalog] =
    useReducer(getDeviceTypesCatalogRed, globalInitialState);

  const [postDeviceTypesCatalogState, dispatchPostDeviceTypesCatalog] =
    useReducer(postDeviceTypesCatalogRed, globalInitialState);

  const [putDeviceTypesCatalogState, dispatchPutDeviceTypesCatalog] =
    useReducer(putDeviceTypesCatalogRed, globalInitialState);

  const [deleteDeviceTypesCatalogState, dispatchDeleteDeviceTypesCatalog] =
    useReducer(deleteDeviceTypesCatalogRed, globalInitialState);

  const [
    postExportDeviceTypesCatalogState,
    dispatchPostExportDeviceTypesCatalog,
  ] = useReducer(postExportDeviceTypesCatalogRed, globalInitialState);

  // Economic numbers

  const [getEconomicNumbersCatalogState, dispatchGetEconomicNumbersCatalog] =
    useReducer(getEconomicNumbersCatalogRed, globalInitialState);

  const [postEconomicNumbersCatalogState, dispatchPostEconomicNumbersCatalog] =
    useReducer(postEconomicNumbersCatalogRed, globalInitialState);

  const [putEconomicNumbersCatalogState, dispatchPutEconomicNumbersCatalog] =
    useReducer(putEconomicNumbersCatalogRed, globalInitialState);

  const [
    deleteEconomicNumbersCatalogState,
    dispatchDeleteEconomicNumbersCatalog,
  ] = useReducer(deleteEconomicNumbersCatalogRed, globalInitialState);

  const [getPcModulesCatalogState, dispatchGetPcModulesCatalog] = useReducer(
    getPcModulesCatalogRed,
    globalInitialState
  );

  // Pos

  const [getPointsOfSalesCatalogState, dispatchGetPointsOfSalesCatalog] =
    useReducer(getPointsOfSalesCatalogRed, globalInitialState);

  const [postPosCatalogState, dispatchPostPosCatalog] = useReducer(
    postPosCatalogRed,
    globalInitialState
  );

  const [putPosCatalogState, dispatchPutPosCatalog] = useReducer(
    putPosCatalogRed,
    globalInitialState
  );

  const [deletePosCatalogState, dispatchDeletePosCatalog] = useReducer(
    deletePosCatalogRed,
    globalInitialState
  );

  //Projects

  const [getProjectsCatalogState, dispatchGetProjectsCatalog] = useReducer(
    getProjectsCatalogRed,
    globalInitialState
  );

  const [postProjectsCatalogState, dispatchPostProjectsCatalog] = useReducer(
    postProjectsCatalogRed,
    globalInitialState
  );

  const [putProjectsCatalogState, dispatchPutProjectsCatalog] = useReducer(
    putProjectsCatalogRed,
    globalInitialState
  );

  const [deleteProjectsCatalogState, dispatchDeleteProjectsCatalog] =
    useReducer(deleteProjectsCatalogRed, globalInitialState);

  // Routes

  const [getRoutesCatalogState, dispatchGetRoutesCatalog] = useReducer(
    getRoutesCatalogRed,
    globalInitialState
  );

  const [postRoutesCatalogState, dispatchPostRoutesCatalog] = useReducer(
    postRoutesCatalogRed,
    globalInitialState
  );

  const [putRoutesCatalogState, dispatchPutRoutesCatalog] = useReducer(
    putRoutesCatalogRed,
    globalInitialState
  );

  const [deleteRoutesCatalogState, dispatchDeleteRoutesCatalog] = useReducer(
    deleteRoutesCatalogRed,
    globalInitialState
  );

  // Stations

  const [getStationsCatalogState, dispatchGetStationsCatalog] = useReducer(
    getStationsCatalogRed,
    globalInitialState
  );

  const [postStationsCatalogState, dispatchPostStationsCatalog] = useReducer(
    postStationsCatalogRed,
    globalInitialState
  );

  const [putStationsCatalogState, dispatchPutStationsCatalog] = useReducer(
    putStationsCatalogRed,
    globalInitialState
  );

  const [deleteStationsCatalogState, dispatchDeleteStationsCatalog] =
    useReducer(deleteStationsCatalogRed, globalInitialState);

  // Transports

  const [
    getTransportCompaniesCatalogState,
    dispatchGetTransportCompaniesCatalog,
  ] = useReducer(getTransportCompaniesCatalogRed, globalInitialState);

  const [
    postTransportCompaniesCatalogState,
    dispatchPostTransportCompaniesCatalog,
  ] = useReducer(postTransportCompaniesCatalogRed, globalInitialState);

  const [
    putTransportCompaniesCatalogState,
    dispatchPutTransportCompaniesCatalog,
  ] = useReducer(putTransportCompaniesCatalogRed, globalInitialState);

  const [
    deleteTransportCompaniesCatalogState,
    dispatchDeleteTransportCompaniesCatalog,
  ] = useReducer(deleteTransportCompaniesCatalogRed, globalInitialState);

  const [getExportCatalogState, dispatchGetExportCatalog] = useReducer(
    getExportCatalogRed,
    globalInitialState
  );

  const inventoryStateContext = useGetInventoryState();

  const inventoryToExcelStateContext = useGetInvetoryToExcelState();

  return (
    <StoreContext.Provider
      value={{
        inventoryStateContext,
        
        inventoryToExcelStateContext,

        openRightMenu,
        setOpenRightMenu,

        loadingState,
        dispatchLoading,

        getSettingsState,
        dispatchSettings,

        /**
         * User Zone
         */

        getUserManagerState,
        dispatchGetUserManager,

        postUserManagerState,
        dispatchPostUserManager,

        patchUserManagerState,
        dispatchPatchUserManager,

        deleteUserManagerState,
        dispatchDeleteUserManager,

        getRolesState,
        dispatchGetRoles,

        postRoleState,
        dispatchPostRole,

        patchRoleState,
        dispatchPatchRole,

        deleteRoleState,
        dispatchDeleteRole,

        /**
         * Sam Zone
         */

        patchEditSamState,
        dispatchPatchEditSam,

        putEditSamState,
        dispatchPutEditSam,

        getSamByIdState,
        dispatchGetSamById,

        getSamTypesState,
        dispatchGetSamTypes,

        putRechargeCounterState,
        dispatchPutRechargeCounter,

        /**
         * Catalogs Zone
         */

        // Operators

        getOperatorsCatalogState,
        dispatchGetOperatorsCatalog,

        postOperatorsCatalogState,
        dispatchPostOperatorsCatalog,

        putOperatorsCatalogState,
        dispatchPutOperatorsCatalog,

        deleteOperatorsCatalogState,
        dispatchDeleteOperatorsCatalog,

        // Locations

        getLocationCatalogState,
        dispatchGetLocationCatalog,

        postLocationsCatalogState,
        dispatchPostLocationsCatalog,

        putLocationsCatalogState,
        dispatchPutLocationsCatalog,

        deleteLocationsCatalogState,
        dispatchDeleteLocationsCatalog,

        // Device types

        getDeviceTypesCatalogState,
        dispatchGetDeviceTypesCatalog,

        postExportDeviceTypesCatalogState,
        dispatchPostExportDeviceTypesCatalog,

        postDeviceTypesCatalogState,
        dispatchPostDeviceTypesCatalog,

        putDeviceTypesCatalogState,
        dispatchPutDeviceTypesCatalog,

        deleteDeviceTypesCatalogState,
        dispatchDeleteDeviceTypesCatalog,

        // Economic numbers

        getEconomicNumbersCatalogState,
        dispatchGetEconomicNumbersCatalog,

        postEconomicNumbersCatalogState,
        dispatchPostEconomicNumbersCatalog,

        putEconomicNumbersCatalogState,
        dispatchPutEconomicNumbersCatalog,

        deleteEconomicNumbersCatalogState,
        dispatchDeleteEconomicNumbersCatalog,

        getPcModulesCatalogState,
        dispatchGetPcModulesCatalog,

        // Pos

        getPointsOfSalesCatalogState,
        dispatchGetPointsOfSalesCatalog,

        postPosCatalogState,
        dispatchPostPosCatalog,

        putPosCatalogState,
        dispatchPutPosCatalog,

        deletePosCatalogState,
        dispatchDeletePosCatalog,

        //Projects

        getProjectsCatalogState,
        dispatchGetProjectsCatalog,

        postProjectsCatalogState,
        dispatchPostProjectsCatalog,

        putProjectsCatalogState,
        dispatchPutProjectsCatalog,

        deleteProjectsCatalogState,
        dispatchDeleteProjectsCatalog,

        // Routes

        getRoutesCatalogState,
        dispatchGetRoutesCatalog,

        postRoutesCatalogState,
        dispatchPostRoutesCatalog,

        putRoutesCatalogState,
        dispatchPutRoutesCatalog,

        deleteRoutesCatalogState,
        dispatchDeleteRoutesCatalog,

        //Stations

        getStationsCatalogState,
        dispatchGetStationsCatalog,

        postStationsCatalogState,
        dispatchPostStationsCatalog,

        putStationsCatalogState,
        dispatchPutStationsCatalog,

        deleteStationsCatalogState,
        dispatchDeleteStationsCatalog,

        // Transport companies

        getTransportCompaniesCatalogState,
        dispatchGetTransportCompaniesCatalog,

        postTransportCompaniesCatalogState,
        dispatchPostTransportCompaniesCatalog,

        putTransportCompaniesCatalogState,
        dispatchPutTransportCompaniesCatalog,

        deleteTransportCompaniesCatalogState,
        dispatchDeleteTransportCompaniesCatalog,

        getExportCatalogState,
        dispatchGetExportCatalog,
      }}>
      {props.children}
    </StoreContext.Provider>
  );
};

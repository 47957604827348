import { FunctionComponent, useContext, useEffect, useRef, useState } from 'react';

import { MainContainer } from '../../layout/components/MainContainer';
import { CatalogsList } from './components/CatalogsList';
import { TableCatalogComponent } from './components/TableCatalogComponent';
import { StoreContext } from '../../context/Provider';
import { RightMenu } from './components/RightMenu';

export const Catalogs: FunctionComponent = () => {
  //States right menu
  const [openRightMenu, setOpenRightMenu] = useState(false);
  const [dataRow, setDataRow] = useState<any>(null);
  const [selectMenu, setSelectMenu] = useState<string>('');
  const [updateTable, setUpdateTable] = useState<any>(null);

  const [catalog, setCatalog] = useState<string>('');

  const menuRef = useRef<HTMLDivElement>(null);

  //context
  const context = useContext(StoreContext);
  const {
    getOperatorsCatalogState,
    getLocationCatalogState,
    getDeviceTypesCatalogState,
    postExportDeviceTypesCatalogState,
    getEconomicNumbersCatalogState,
    getPcModulesCatalogState,
    getPointsOfSalesCatalogState,
    getProjectsCatalogState,
    getRoutesCatalogState,
    getStationsCatalogState,
    getTransportCompaniesCatalogState,
    getExportCatalogState,
    deleteTransportCompaniesCatalogState,
    deleteProjectsCatalogState,
    deleteRoutesCatalogState,
    deleteStationsCatalogState,
    deleteOperatorsCatalogState,
    deleteLocationsCatalogState,
    deleteDeviceTypesCatalogState,
    deleteEconomicNumbersCatalogState,
    deletePosCatalogState,
    postPosCatalogState,
    putPosCatalogState,
    postProjectsCatalogState,
    putProjectsCatalogState,
    postRoutesCatalogState,
    putRoutesCatalogState,
    postStationsCatalogState,
    putStationsCatalogState,
    postTransportCompaniesCatalogState,
    putTransportCompaniesCatalogState,
    postOperatorsCatalogState,
    putOperatorsCatalogState,
    postLocationsCatalogState,
    putLocationsCatalogState,
    postDeviceTypesCatalogState,
    putDeviceTypesCatalogState,
    postEconomicNumbersCatalogState,
    putEconomicNumbersCatalogState,
  } = context;

  const selectCatalog = (catalog: string) => {
    setCatalog(catalog);
  };

  const [documentName, setDocumentName] = useState<string>('');

  const selectDocumentName = (catalog: string) => {
    setDocumentName(catalog);
  };

  useEffect(()=>{
    document.addEventListener('mousedown',handleClickOutside);
    return()=>{
      document.removeEventListener('mousedown',handleClickOutside);
    }
  },[])

  const loading =
    getOperatorsCatalogState.loading ||
    getLocationCatalogState.loading ||
    getDeviceTypesCatalogState.loading ||
    postExportDeviceTypesCatalogState.loading ||
    getEconomicNumbersCatalogState.loading ||
    getPcModulesCatalogState.loading ||
    getPointsOfSalesCatalogState.loading ||
    getProjectsCatalogState.loading ||
    getRoutesCatalogState.loading ||
    getStationsCatalogState.loading ||
    getTransportCompaniesCatalogState.loading ||
    getExportCatalogState.loading ||
    deleteTransportCompaniesCatalogState.loading ||
    deleteProjectsCatalogState.loading ||
    deleteRoutesCatalogState.loading ||
    deleteStationsCatalogState.loading ||
    deleteOperatorsCatalogState.loading ||
    deleteLocationsCatalogState.loading ||
    deleteDeviceTypesCatalogState.loading ||
    deleteEconomicNumbersCatalogState.loading ||
    deletePosCatalogState.loading ||
    postPosCatalogState.loading ||
    putPosCatalogState.loading ||
    postProjectsCatalogState.loading ||
    putProjectsCatalogState.loading ||
    postRoutesCatalogState.loading ||
    putRoutesCatalogState.loading ||
    postStationsCatalogState.loading ||
    putStationsCatalogState.loading ||
    postTransportCompaniesCatalogState.loading ||
    putTransportCompaniesCatalogState.loading ||
    postOperatorsCatalogState.loading ||
    putOperatorsCatalogState.loading ||
    postLocationsCatalogState.loading ||
    putLocationsCatalogState.loading ||
    postDeviceTypesCatalogState.loading ||
    putDeviceTypesCatalogState.loading ||
    postEconomicNumbersCatalogState.loading ||
    putEconomicNumbersCatalogState.loading;

  const handleClickOutside = (e: MouseEvent)=>{
    const target = e.target as Node; 
    if (menuRef.current && !menuRef.current.contains(target) && !document.querySelector('.p-dropdown-panel')?.contains(target)) 
    {
      setOpenRightMenu(false);
    }
  }

  const renderContent = () => {
    return (
      <div className="flex w-full h-full relative flex-row">
        <div className="flex h-full w-[14%] border-solid border-r-2 border-grey relative">
          <CatalogsList
            setCatalog={selectCatalog}
            setDocumentName={selectDocumentName}
            setOpenRightMenu={setOpenRightMenu}
          />
        </div>

        <div className="flex grow h-full w-[86%] relative flex-col content-center items-center justify-center p-10 divide-solid">
          <TableCatalogComponent
            titleProp={catalog}
            documentName={documentName}
            loading={loading}
            setOpenRightMenu={setOpenRightMenu}
            setDataRow={setDataRow}
            selectMenu={setSelectMenu}
            setUpdateTable={setUpdateTable}
          />
        </div>
      </div>
    );
  };

  const renderRightComponent = () => {
    return (
      <RightMenu
        menuRef={menuRef}
        dataRow={dataRow}
        selectMenu={selectMenu}
        setOpenRightMenu={setOpenRightMenu}
        updateTable={updateTable}
      />
    );
  };

  return (
    <MainContainer
      loading={loading}
      openRightMenu={openRightMenu}
      setOpenRightMenu={setOpenRightMenu}
      rightMenuContent={renderRightComponent}>
      {renderContent()}
    </MainContainer>
  );
};

import { useContext } from 'react';

import { ToastComponent } from '../../components/ToastComponent';
import {
  GET_OPERATORS_CATALOG,
  GET_OPERATORS_CATALOG_SUCCESS,
  GET_OPERATORS_CATALOG_ERROR,
  POST_OPERATORS_CATALOG,
  POST_OPERATORS_CATALOG_SUCCESS,
  POST_OPERATORS_CATALOG_ERROR,
  PUT_OPERATORS_CATALOG,
  PUT_OPERATORS_CATALOG_SUCCESS,
  PUT_OPERATORS_CATALOG_ERROR,
  DELETE_OPERATORS_CATALOG,
  DELETE_OPERATORS_CATALOG_SUCCESS,
  DELETE_OPERATORS_CATALOG_ERROR,
} from '../../constants/constantsRed';
import { StoreContext } from '../../context/Provider';
import useApi from '../../services/request.service';

export const useOperatorsCatalogs = () => {
  const context = useContext(StoreContext);
  const {
    dispatchGetOperatorsCatalog,
    dispatchDeleteOperatorsCatalog,
    dispatchPostOperatorsCatalog,
    dispatchPutOperatorsCatalog,
  } = context;
  const { genericApiConfiguration } = useApi();

  const getOperatorsCatalog = async () => {
    dispatchGetOperatorsCatalog({ type: GET_OPERATORS_CATALOG });
    try {
      const result: any = await genericApiConfiguration(
        '/operators',
        'GET',
        {},
        {},
        {}
      );
      if (result.status === 200) {
        const payload = result.data.payload;
        dispatchGetOperatorsCatalog({
          type: GET_OPERATORS_CATALOG_SUCCESS,
          payload: payload,
        });
      }
      return result;
    } catch (error: any) {
      ToastComponent(
        'warning',
        'Error',
        'La operación falló, inténtelo más tarde por favor.'
      );
      dispatchGetOperatorsCatalog({
        type: GET_OPERATORS_CATALOG_ERROR,
        payload: error.message,
      });
      return 'error';
    }
  };

  const postOperatorsCatalog = async (data: any) => {
    dispatchPostOperatorsCatalog({ type: POST_OPERATORS_CATALOG });
    try {
      const result: any = await genericApiConfiguration(
        '/operators',
        'POST',
        data,
        {},
        {}
      );
      if (result.status === 200) {
        ToastComponent('success', '', 'Se ha añadido el registro correctamente');
        const payload = result.data.payload;
        dispatchPostOperatorsCatalog({
          type: POST_OPERATORS_CATALOG_SUCCESS,
          payload: payload,
        });
      }
      return result;
    } catch (error: any) {
      dispatchPostOperatorsCatalog({
        type: POST_OPERATORS_CATALOG_ERROR,
        payload: error.message,
      });
      return 'error';
    }
  };

  const putOperatorsCatalog = async (data: any) => {
    dispatchPutOperatorsCatalog({ type: PUT_OPERATORS_CATALOG });
    try {
      const result: any = await genericApiConfiguration(
        '/operators',
        'PUT',
        data,
        {},
        {}
      );
      if (result.status === 200) {
        ToastComponent('success', '', 'Se ha editado el registro correctamente');
        const payload = result.data.payload;
        dispatchPutOperatorsCatalog({
          type: PUT_OPERATORS_CATALOG_SUCCESS,
          payload: payload,
        });
      }
      return result;
    } catch (error: any) {
      dispatchPutOperatorsCatalog({
        type: PUT_OPERATORS_CATALOG_ERROR,
        payload: error.message,
      });
      return 'error';
    }
  };

  const deleteOperatorsCatalog = async (id: string) => {
    dispatchDeleteOperatorsCatalog({ type: DELETE_OPERATORS_CATALOG });
    try {
      const result: any = await genericApiConfiguration(
        '/operators/{id}',
        'DELETE',
        {},
        { ids: id },
        {}
      );

      if (result.status === 200) {
        const payload = result.data.payload;
        dispatchDeleteOperatorsCatalog({
          type: DELETE_OPERATORS_CATALOG_SUCCESS,
          payload: payload,
        });
      }
      return result;
    } catch (error: any) {
      dispatchDeleteOperatorsCatalog({
        type: DELETE_OPERATORS_CATALOG_ERROR,
        payload: error.message,
      });
      return 'error';
    }
  };

  return {
    getOperatorsCatalog,
    postOperatorsCatalog,
    putOperatorsCatalog,
    deleteOperatorsCatalog,
  };
};

import { FunctionComponent, useEffect, useState } from 'react';

import { ButtonComponent } from '../../../components/ButtonComponen';
import { DialogPopUpComponent } from '../../../components/DialogPopUpComponent';
import { InputComponent } from '../../../components/InputComponent';
import { ToastComponent } from '../../../components/ToastComponent';
import { useEditSam } from '../../../hooks/inventory/useEditSam';
import { getCurrentPosition } from '../../../utils/utils';

interface IWhiteListSamProps {
  setDataRow?: any;
  handlerUpdateData: any;
  show: boolean;
  closeMenu: any;
}

export const ModifyWhiteListSam = (props: IWhiteListSamProps) => {
  const { setDataRow, handlerUpdateData, show, closeMenu } = props;

  const [visible, setVisible] = useState<boolean>(false);
  const [value, setValue] = useState<string>('');
  const [header, setHeader] = useState<string>('');

  const { patchEditSam } = useEditSam();

  useEffect(() => {
    setVisible(show);
    if (Object.keys(setDataRow).length > 0)
      if (setDataRow.data['WhiteList'] === true) {
        setHeader('Quitar de la lista blanca');
      } else {
        setHeader('Agregar a la lista blanca');
      }
  }, [props]);

  const close = (value?: boolean) => {
    setVisible(value || true);
    closeMenu(false);
  };

  const bodyContent = () => {
    return (
      <div className="w-full h-full flex p-6 justify-center">
        <InputComponent
          type="text"
          name="Comentarios"
          placeholder="Observaciones"
          label="Observaciones"
          value={value}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setValue(e.target.value)
          }
        />
      </div>
    );
  };

  const footerContent = () => {
    return (
      <div className="w-full h-full flex pb-4 justify-center">
        <ButtonComponent
          label="Cancelar"
          onClick={() => close(false)}
          className="p-button-text !bg-deleteButton !text-white"
        />
        <ButtonComponent
          label="Aceptar"
          onClick={() => getResponse()}
          disbaled={value === ''}
        />
      </div>
    );
  };

  const getResponse = async () => {
    let itemValue = !setDataRow.data['WhiteList'];

    try {
      let data = {
        operation: 'replace',
        path: 'WhiteList',
        value: itemValue.toString(),
        description: value,
      };

      let coords = await getCurrentPosition();

      const headers = {
        locationGPS: `${coords.latitude}, ${coords.latitude}`,
        appId: 'web',
      };

      let response = await patchEditSam(
        data,
        headers,
        setDataRow.data['SamId']
      );

      if (response !== 'error') {
        setVisible(false);
      }
      handlerUpdateData(response.data);
      setValue('');
    } catch (error: any) {
      ToastComponent('warning', 'Error', error.toString());
    }
  };

  return (
    <div>
      {visible === true ? (
        <DialogPopUpComponent
          show={visible}
          header={header}
          body={bodyContent()}
          footer={footerContent()}
          close={close}
        />
      ) : (
        <></>
      )}
    </div>
  );
};

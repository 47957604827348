import useApi from "../../services/request.service";
import { StoreContext } from "../../context/Provider";
import { useContext, useEffect } from "react";
import { GET_INVENTORY, GET_INVENTORY_ERROR, GET_INVENTORY_EXCEL, GET_INVENTORY_EXCEL_ERROR, GET_INVENTORY_EXCEL_SUCCESS, GET_INVENTORY_SUCCESS } from "../../constants/inventory";
import { ToastComponent } from "../../components/ToastComponent";

export const useInventoryService = () => {
  const {inventoryStateContext, inventoryToExcelStateContext} = useContext(StoreContext);
  const {genericApiCallApiBilling} = useApi();
  const baseItem = localStorage.getItem('queryGetInventory');
  
  const getInventoryData = async(queryRequest: string = '') =>{
    localStorage.setItem('queryGetInventory', queryRequest);
    inventoryStateContext.dispatch({type: GET_INVENTORY});
    const _baseItem = queryRequest === '' ? baseItem : queryRequest;
    if(_baseItem !== null || _baseItem !== ''){
      try {
        const urlQuery: string =`/v1.0/sam-inventory?${_baseItem}`;
        const result: any = await genericApiCallApiBilling(urlQuery, 'GET', {}, {}, {})
        
        const payload = result.data;
        if (result.status === 200) {
          inventoryStateContext.dispatch({type: GET_INVENTORY_SUCCESS, payload: payload});
        }
        return result;
      } catch (error: any) {
        ToastComponent(
        '',
        '',
        error.message,
        6000
      );
      }
    }else{
      ToastComponent(
        '',
        '',
        'Seleccione algún filtro y el botón enviar para mostrar resultados.',
        6000
      );
      inventoryStateContext.dispatch({type: GET_INVENTORY_ERROR});
    }
  }

  const inventoryExcel = async(queryRequest: string = '', columnsQueryParams: any) =>{
    inventoryToExcelStateContext.dispatch({type: GET_INVENTORY_EXCEL});
    try {
      const urlQuery = `/v1.0/sam-inventory/download?${queryRequest}&${columnsQueryParams}`
      const result: any = await genericApiCallApiBilling(urlQuery, 'GET', {}, {}, {});
      const payload = result.data;
      if(result.status === 200){
        inventoryToExcelStateContext.dispatch({type: GET_INVENTORY_EXCEL_SUCCESS, payload: payload})
        if (payload !== null && payload !== undefined) {
          let anchor = document.createElement('a');
          anchor.href = 'data:text/csv;charset=utf-8,' + encodeURI(payload);
          anchor.target = '_blank';
          anchor.download = 'Inventario.csv';
          anchor.click();
        }else if(payload === ''){
          ToastComponent(
            'warning',
            'Error',
            'No hay datos para excel.',
            6000
          );
        }else{
          ToastComponent(
            'warning',
            'Error',
            'Hay un problema en la solicitud.',
            6000
          );
        }
      }
    } catch (error: any) {
      ToastComponent(
        'warning',
        'Error',
        error.message,
        6000
      );
      inventoryToExcelStateContext.dispatch({type: GET_INVENTORY_EXCEL_ERROR})
    }
  }

  useEffect(() => {
    if(baseItem === null){
      ToastComponent(
        '',
        '',
        'Seleccione algún filtro y el botón enviar para mostrar resultados.',
        6000
      );
    }
    
  }, [])
  
  return {getInventoryData, inventoryExcel};
}
import { useContext } from 'react';

import { ToastComponent } from '../../components/ToastComponent';
import {
  PATCH_EDIT_SAM,
  PATCH_EDIT_SAM_ERROR,
  PATCH_EDIT_SAM_SUCCESS,
  PUT_EDIT_SAM,
  PUT_EDIT_SAM_ERROR,
  PUT_EDIT_SAM_SUCCESS,
  GET_SAM_BY_ID,
  GET_SAM_BY_ID_SUCCESS,
  GET_SAM_BY_ID_ERROR,
} from '../../constants/constantsRed';
import { StoreContext } from '../../context/Provider';
import useApi from '../../services/request.service';

export const useEditSam = () => {
  const context = useContext(StoreContext);
  const { dispatchPatchEditSam, dispatchGetSamById } = context;
  const { genericApiCallApiBilling } = useApi();

  const patchEditSam = async (
    data: any,
    headers: any,
    samId: string,
    sendNotification: boolean = true
  ) => {
    dispatchPatchEditSam({ type: PATCH_EDIT_SAM });
    try {
      const result: any = await genericApiCallApiBilling(
        '/v1.0/sam-inventory/' + samId,
        'PATCH',
        data,
        {},
        headers
      );
      if (result.status === 200) {
        if (sendNotification === true) {
          ToastComponent('success', '', 'Actualizado correctamente');
        }
        const payload = result.data.payload;
        dispatchPatchEditSam({
          type: PATCH_EDIT_SAM_SUCCESS,
          payload: payload,
        });
      }
      return result;
    } catch (error: any) {
      ToastComponent(
        'warning',
        'Error',
        'La operación falló, inetnet más tarde por favor.'
      );
      dispatchPatchEditSam({
        type: PATCH_EDIT_SAM_ERROR,
        payload: error.message,
      });
      return 'error';
    }
  };

  const getSamById = async (samId: any, aditionalPath: string = '') => {
    dispatchGetSamById({ type: GET_SAM_BY_ID });
    try {
      const result: any = await genericApiCallApiBilling(
        '/v1.0/sam-inventory/' + aditionalPath + samId,
        'GET',
        {},
        {},
        {}
      );
      if (result.status === 200) {
        const payload = result.data.payload;
        dispatchGetSamById({
          type: GET_SAM_BY_ID_SUCCESS,
          payload: payload,
        });
      }
      return result;
    } catch (error: any) {
      ToastComponent('warning', 'Error', error.message);
      dispatchGetSamById({
        type: GET_SAM_BY_ID_ERROR,
        payload: error.message,
      });
    }
  };

  const putEditSam = async (data: any, headers: any) => {
    dispatchPatchEditSam({ type: PUT_EDIT_SAM });
    try {
      const result: any = await genericApiCallApiBilling(
        '/v1.0/sam-inventory',
        'PUT',
        data,
        {},
        headers
      );
      if (result.status === 200) {
        ToastComponent('success', '', 'Actualizado correctamente');
        const payload = result.data.payload;
        dispatchPatchEditSam({
          type: PUT_EDIT_SAM_SUCCESS,
          payload: payload,
        });
      }
      return result;
    } catch (error: any) {
      ToastComponent('warning', 'Error', error.message);
      dispatchPatchEditSam({
        type: PUT_EDIT_SAM_ERROR,
        payload: error.message,
      });
    }
  };

  return { patchEditSam, putEditSam, getSamById };
};

import React, {
  FunctionComponent,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Outlet } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import { StoreContext } from '../context/Provider';
import { NavBar } from './components/NavBar/header';

///import { RightMenuConfigurationComponent } from '../components/RightMenuConfigurationComponent';
//import { ThemeContext } from '../context/ThemeContext';
export const MainLayout: FunctionComponent = () => {
  const context = useContext(StoreContext);
  const { setOpenRightMenu, openRightMenu } = context;
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [showPopover, setShowPopover] = useState(false);
  const rightMenuRef = useRef<HTMLDivElement>(null);
  
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        rightMenuRef.current &&
        !rightMenuRef.current.contains(event.target as Node) &&
        userButtonRef.current &&
        !userButtonRef.current.contains(event.target as Node)
      ) {
        setOpenRightMenu(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const userMenuRef = useRef<HTMLDivElement>(null);
  const userButtonRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutsideUser = (event: MouseEvent) => {
      if (
        userMenuRef.current &&
        !userMenuRef.current.contains(event.target as Node)
      ) {
        setShowPopover(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutsideUser);

    return () => {
      document.removeEventListener('mousedown', handleClickOutsideUser);
    };
  }, []);

  useEffect(() => {
    const handleClickOutsideUser = (event: MouseEvent) => {
      if (
        userMenuRef.current &&
        !userMenuRef.current.contains(event.target as Node)
      ) {
        setIsMenuOpen(false);
        setShowPopover(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutsideUser);

    return () => {
      document.removeEventListener('mousedown', handleClickOutsideUser);
    };
  }, []);

  return (
    <div className="flex flex-col w-full h-screen relative">
      <NavBar
        isMenuOpen={isMenuOpen}
        setIsMenuOpen={setIsMenuOpen}
        userMenuRef={userMenuRef}
        userButtonRef={userButtonRef}
        showPopover={showPopover}
        setShowPopover={setShowPopover}
        //userButtonRef={userButtonRef}
      />
      <div className="flex h-full w-full relative ">
        <Outlet />
        <ToastContainer
          position="bottom-right"
          autoClose={1000}
          hideProgressBar={false}
          newestOnTop={false}
          //closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
      </div>
    </div>
  );
};

import React, { useState } from 'react';
import { ProgressSpinner } from 'primereact/progressspinner';

export const LoaderComponent = () => {
  return (
    <div className="bg-white flex h-screen w-full justify-center content-center items-center flex-col !absolute z-20">
      <ProgressSpinner
        style={{ width: '50px', height: '50px' }}
        className="flex w-[50px] h-[50px]"
        strokeWidth="8"
        fill="var(--surface-ground)"
        animationDuration=".5s"
      />
    </div>
  );
};

import {
  ITransportCompanies,
  IServiceMenuComponent,
} from '@/interfaces/interfaceCatalogs';
import { useFormik } from 'formik';
import { classNames } from 'primereact/utils';
import { useEffect, useState } from 'react';

import { createObjectAsInterface } from '../../../../utils/utils';
import { ButtonComponent } from '../../../../components/ButtonComponen';
import { InputComponent } from '../../../../components/InputComponent';
import { useTransportCompaniesCatalogs } from '../../../../hooks/catalogs/useTransportCompaniesCatalogs';
import { validationsTransportCompanies } from './validations_form';
import { SwitchComponent } from '../../../../components/SwitchComponent';
import { InputSwitchChangeEvent } from 'primereact/inputswitch';

export const TransportCompaniesForm = (props: IServiceMenuComponent) => {
  const { dataRow, selectMenu, updateTable } = props;

  /**
   * Crea un objeto vacio para usarse en el initialValues de formik con la interface del formulario
   */
  const objAsTransportCompaniesInterface: ITransportCompanies = {
    businessName: '',
    eur: '',
    status: false,
  };

  /**
   * Estado que guarda el objeto creado de acuerdo a la interface del formulario
   */
  const [inputs, setInputs] = useState<ITransportCompanies>(
    objAsTransportCompaniesInterface
  );

  /**
   * Texto del boton.
   * {string} Puede ser Agregar | Actualizar
   */
  const [buttonLabel, setButtonLabel] = useState<string>('Agregar');

  //Hooks

  const { putTransportCompaniesCatalog, postTransportCompaniesCatalog } =
    useTransportCompaniesCatalogs();

  /**
   * Si es una edicion de un item, se toma el objeto seleccionado y se le da el formato para que se usen los datos en el formik
   */
  useEffect(() => {
    if (dataRow !== undefined) {
      setButtonLabel('Actualizar');
      let obj = createObjectAsInterface(
        dataRow,
        objAsTransportCompaniesInterface
      );
      setInputs(obj);
    }
  }, [dataRow]);

  /**
   * Se manda la peticion al back
   */
  const handleSubmit = async (values: any) => {
    let response: any = {};

    if (buttonLabel === 'Actualizar') {
      let id = dataRow.id;
      values['id'] = id;
      response = await putTransportCompaniesCatalog(values);
    } else if (buttonLabel === 'Agregar') {
      response = await postTransportCompaniesCatalog(values);
    }
    if (response.status === 200) {
      updateTable(response.status);
    }
  };

  const formik = useFormik({
    initialValues: inputs,
    enableReinitialize: true,
    validationSchema: validationsTransportCompanies,
    onSubmit: data => {
      handleSubmit(data);
    },
  });

  const isFormFieldValid = (name: 'businessName' | 'eur' | 'status') =>
    !!(formik.touched[name] && formik.errors[name]);

  const getFormErrorMessage = (name: 'businessName' | 'eur' | 'status') => {
    return (
      isFormFieldValid(name) && (
        <small className="p-error">{formik.errors[name]}</small>
      )
    );
  };

  const renderMenu = (): any => {
    return (
      <form
        className="h-full w-full flex flex-col pt-5 px-6"
        onSubmit={formik.handleSubmit}>
        <div className="flex flex-col">
          <InputComponent
            label={'Nombre de compañía'}
            type={'businessName'}
            name={'businessName'}
            onChange={formik.handleChange}
            value={formik?.values?.['businessName'] || ''}
            placeholder={'Nombre de compañía/Razón social'}
            className={classNames({
              'p-invalid': isFormFieldValid('businessName'),
            })}
          />
          {getFormErrorMessage('businessName')}
        </div>

        <div className="flex flex-col">
          <InputComponent
            label={'EUR'}
            type={'eur'}
            name={'eur'}
            onChange={formik.handleChange}
            value={formik?.values?.['eur'] || ''}
            placeholder={'EUR'}
            className={classNames({
              'p-invalid': isFormFieldValid('eur'),
            })}
          />
          {getFormErrorMessage('eur')}
        </div>

        <div className="flex flex-col">
          <SwitchComponent
            checked={formik?.values?.['status'] || 'false'}
            onChange={(e: InputSwitchChangeEvent) => {
              formik.setFieldValue('status', e.value);
            }}
            label={'Estatus'}
            className={classNames({
              'p-invalid': isFormFieldValid('status'),
            })}
          />
          {getFormErrorMessage('status')}
        </div>

        <ButtonComponent className="mt-10" label={buttonLabel} type="submit" />
      </form>
    );
  };

  return (
    <div className="flex w-full h-full flex-col items-center bg-white  shadow-[-6px_-1px_5px_#00000024] z-10 right-0">
      <div className="flex w-full h-full overflow-y-auto">{renderMenu()}</div>
    </div>
  );
};

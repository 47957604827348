import {
  IEconomicNumber,
  IServiceMenuComponent,
} from '@/interfaces/interfaceCatalogs';
import { useFormik } from 'formik';
import { InputSwitchChangeEvent } from 'primereact/inputswitch';
import { classNames } from 'primereact/utils';
import { useContext, useEffect, useState } from 'react';

import { BasicSelectComponent } from '../../../../components/BasicSelectComponentComponent';
import { ButtonComponent } from '../../../../components/ButtonComponen';
import { InputComponent } from '../../../../components/InputComponent';
import { SwitchComponent } from '../../../../components/SwitchComponent';
import { LayoutContext } from '../../../../context/LayoutContext';
import { useEconomicNumbersCatalogs } from '../../../../hooks/catalogs/useEconomicNumbersCatalogs';
import { useRoutesCatalogs } from '../../../../hooks/catalogs/useRoutesCatalogs';
import { createObjectAsInterface } from '../../../../utils/utils';
import { economicNumberValidations } from './validations_form';
import { DropdownComponent } from '../../../../components/DropdownComponen';

export const EconomicNumberForm = (props: IServiceMenuComponent) => {
  const { dataRow, selectMenu, updateTable } = props;

  /**
   * Crea un objeto vacio para usarse en el initialValues de formik con la interface del formulario
   */
  const objAsEcnomicNumberInterface: IEconomicNumber = {
    economicNumber: '',
    routeId: '',
    status: false,
  };

  /**
   * Estado que guarda el objeto creado de acuerdo a la interface del formulario
   */
  const [inputs, setInputs] = useState<IEconomicNumber>(
    objAsEcnomicNumberInterface
  );

  const [routes, setRoutes] = useState<any>();

  /**
   * Texto del boton.
   * {string} Puede ser Agregar | Actualizar
   */
  const [buttonLabel, setButtonLabel] = useState<string>('Agregar');

  //Hooks

  const { getRoutesCatalog } = useRoutesCatalogs();
  const { postEconomicNumbersCatalog, putEconomicNumbersCatalog } =
    useEconomicNumbersCatalogs();

  const context = useContext(LayoutContext);
  const { changeStatusCloseMenu } = context;

  /**
   * Se piden los catalogos necesarios para llenar los select
   */
  useEffect(() => {
    const getData = async () => {
      let response = await getRoutesCatalog();
      if (response.status === 200) {
        setRoutes(response.data.message);
      }
    };

    getData();
    changeStatusCloseMenu(true);
  }, []);

  /**
   * Si es una edicion de un item, se toma el objeto seleccionado y se le da el formato para que se usen los datos en el formik
   */
  useEffect(() => {
    if (dataRow !== undefined) {
      setButtonLabel('Actualizar');
      let obj = createObjectAsInterface(dataRow, objAsEcnomicNumberInterface);
      setInputs(obj);
    }
  }, [dataRow]);

  /**
   * Se manda la peticion al back
   */
  const handleSubmit = async (values: any) => {
    let response: any = {};

    values['routeId'] = Number(values['routeId']);

    if (buttonLabel === 'Actualizar') {
      let id = dataRow.economicNumberId;
      values['economicNumberId'] = id;
      response = await putEconomicNumbersCatalog(values);
    } else if (buttonLabel === 'Agregar') {
      response = await postEconomicNumbersCatalog(values);
    }
    if (response.status === 200) {
      updateTable(response.status);
      changeStatusCloseMenu(false);
    }
  };

  const formik = useFormik({
    initialValues: inputs,
    enableReinitialize: true,
    validationSchema: economicNumberValidations,
    onSubmit: data => {
      handleSubmit(data);
    },
  });

  const isFormFieldValid = (name: 'economicNumber' | 'routeId' | 'status') =>
    !!(formik.touched[name] && formik.errors[name]);

  const getFormErrorMessage = (
    name: 'economicNumber' | 'routeId' | 'status'
  ) => {
    return (
      isFormFieldValid(name) && (
        <small className="p-error">{formik.errors[name]}</small>
      )
    );
  };

  const renderMenu = (): any => {
    return (
      <form
        className="h-full w-full flex flex-col pt-5 px-6"
        onSubmit={formik.handleSubmit}>
        {/*         <div className="flex flex-col">
          <InputNumberComponent
            label={'Id de número economico'}
            name={'economicNumberId'}
            onValueChange={formik.handleChange}
            value={formik?.values?.['economicNumberId']}
            placeholder={'Id de número economico'}
            className={classNames({
              'p-invalid': isFormFieldValid('economicNumberId'),
            })}
          />
          {getFormErrorMessage('economicNumberId')}
        </div> */}

        <div className="flex flex-col">
          <InputComponent
            label={'Número de unidad'}
            type={'text'}
            name={'economicNumber'}
            onChange={formik.handleChange}
            value={formik?.values?.['economicNumber']}
            placeholder={'Número de unidad'}
            className={classNames({
              'p-invalid': isFormFieldValid('economicNumber'),
            })}
          />
          {getFormErrorMessage('economicNumber')}
        </div>

        <div className="flex flex-col">
          <DropdownComponent
            label={'Ruta'}
            options={routes}
            optionLabel={'name'}
            name={'routeId'}
            optionValue={'routeId'}
            onChange={formik.handleChange}
            value={formik?.values?.['routeId']}
            filter={true}
          />
          {getFormErrorMessage('routeId')}
        </div>

        <div className="flex flex-col">
          <SwitchComponent
            checked={formik?.values?.['status'] || 'false'}
            onChange={(e: InputSwitchChangeEvent) => {
              formik.setFieldValue('status', e.value);
            }}
            label={'Estatus'}
            className={classNames({
              'p-invalid': isFormFieldValid('status'),
            })}
          />
          {getFormErrorMessage('status')}
        </div>

        <ButtonComponent className="mt-10" label={buttonLabel} type="submit" />
      </form>
    );
  };

  return (
    <div className="flex w-full h-full flex-col items-center bg-white  shadow-[-6px_-1px_5px_#00000024] z-10 right-0">
      <div className="flex w-full h-full overflow-y-auto">{renderMenu()}</div>
    </div>
  );
};

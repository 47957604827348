import { Column } from 'primereact/column';
import { useContext, useEffect, useState } from 'react';

import { DialogPopUpComponent } from '../../../components/DialogPopUpComponent';
import { StoreContext } from '../../../context/Provider';
import { useEditSam } from '../../../hooks/inventory/useEditSam';
import { TableComponent } from './../../../components/Table';

interface IWhiteListSamProps {
  samSelected?: any;
  show: boolean;
  closeMenu?: any;
}

export const RechargesCounterHistoryModal = (props: IWhiteListSamProps) => {
  const { samSelected, show, closeMenu } = props;

  const [visible, setVisible] = useState<boolean>(false);
  const [sort, setSort] = useState([{ field: '', order: -1 }]);
  const [history, setHistory] = useState<any[]>([]);
  const context = useContext(StoreContext);
  const { getSamByIdState } = context;

  const { getSamById } = useEditSam();

  useEffect(() => {
    setVisible(show);
    async function getSam() {
      let response = await getSamById(samSelected.Id, 'counters/');
      if (response.status === 200) {
        setHistory(response.data);
      }
    }
    if (samSelected !== undefined) {
      getSam();
    }
  }, [props]);

  const close = (value?: boolean) => {
    setVisible(value || true);
    closeMenu(false);
  };

  const columnsDisplayInTable = [
    {
      field: 'createdDate',
      header: 'Fecha',
      sortable: true,
    },
    {
      field: 'createdBy',
      header: 'Operador',
      sortable: true,
    },
    {
      field: 'amount',
      header: 'Cantidad',
      sortable: true,
    },
  ];

  const bodyContent = () => {
    return (
      <div className="w-full h-80 mb-8 flex p-6 justify-center relative">
        <TableComponent
          value={history}
          loading={getSamByIdState.loading}
          sortField="name"
          responsiveLayout="scroll"
          globalFilterFields={[]}
          setSort={setSort}
          sort={sort}
          resizableColumns
          reorderableColumns={true}
          paginator={false}>
          {columnsDisplayInTable.map((column: any, index: any) => {
            const key = index.toString();
            return (
              <Column
                key={key}
                field={column.field}
                header={column.header}
                bodyClassName={'!text-black'}
                style={{
                  flexGrow: 1,
                  flexBasis: '100px',
                }}
                headerClassName="  !bg-tableHeader !text-withe!justify-between "
                sortable={column.sortable}
                filterMenuStyle={{ width: '14rem' }}
                filterElement={column.filterElement}
                filter={column.filter}
                body={column.body}
              />
            );
          })}
        </TableComponent>
      </div>
    );
  };

  return (
    <div className="">
      {visible === true ? (
        <DialogPopUpComponent
          show={visible}
          header={`Historial de contadores para la sam ${samSelected?.SamId}`}
          body={bodyContent()}
          close={close}
        />
      ) : (
        <></>
      )}
    </div>
  );
};

import { FunctionComponent, useEffect, useState } from 'react';

import { ButtonComponent } from '../../../components/ButtonComponen';
import { DialogPopUpComponent } from '../../../components/DialogPopUpComponent';
import { useEconomicNumbersCatalogs } from '../../../hooks/catalogs/useEconomicNumbersCatalogs';
import { useLocationCatalogs } from '../../../hooks/catalogs/useLocationsCatalogs';
import { useOperatorsCatalogs } from '../../../hooks/catalogs/useOperatorsCatalogs';
import { usePointsOfSalesCatalog } from '../../../hooks/catalogs/usePointsOfSalesCatalog';
import { useDeviceTypesCatalogs } from '../../../hooks/catalogs/useDeviceTypesCatalogs';
import { useRoutesCatalogs } from '../../../hooks/catalogs/useRoutesCatalogs';
import { useStationsCatalogs } from '../../../hooks/catalogs/useStationsCatalogs';
import { usegetProjectsCatalogs } from '../../../hooks/catalogs/useProjectsCatalog';
import { useTransportCompaniesCatalogs } from '../../../hooks/catalogs/useTransportCompaniesCatalogs';
import { ToastComponent } from '../../../components/ToastComponent';

interface IWhiteListSamProps {
  handlerCloseModal?: any;
  itemToDelete: any;
  showDeleteModal: any;
  catalog?: string;
  updateTable?: any;
}

export const DeleteItemModal = (props: IWhiteListSamProps) => {
  const {
    handlerCloseModal,
    itemToDelete,
    showDeleteModal,
    catalog,
    updateTable,
  } = props;

  //States
  const [visible, setVisible] = useState<boolean>(false);
  const [header] = useState<string>('Eliminar registro');

  //Hooks

  const { deleteStationsCatalog } = useStationsCatalogs();
  const { deleteLocationsCatalog } = useLocationCatalogs();
  const { deleteTransportCompaniesCatalog } = useTransportCompaniesCatalogs();
  const { deleteEconomicNumbersCatalog } = useEconomicNumbersCatalogs();
  const { deleteOperatorsCatalog } = useOperatorsCatalogs();
  const { deletePointsOfSalesCatalog } = usePointsOfSalesCatalog();
  const { deleteDeviceTypesCatalog } = useDeviceTypesCatalogs();
  const { deleteRoutesCatalog } = useRoutesCatalogs();
  const { deleteProjectsCatalog } = usegetProjectsCatalogs();

  useEffect(() => {
    setVisible(showDeleteModal);
  }, [props]);

  const close = (value?: boolean) => {
    setVisible(value || true);
    handlerCloseModal(false);
  };

  const bodyModalContent = () => {
    let name = '';

    if (itemToDelete !== undefined && itemToDelete['name']) {
      name = itemToDelete['name'];
    } else if (itemToDelete['economicNumber']) {
      name = itemToDelete['economicNumber'];
    } else if (itemToDelete['businessName']) {
      name = itemToDelete['businessName'];
    } else if (itemToDelete['locationId']) {
      name = itemToDelete['locationId'];
    }
    return (
      <div className="w-full h-full flex pb-4 justify-center">
        <p>
          ¿Deseas eliminar <strong> {name}</strong> del catálogo?
        </p>
      </div>
    );
  };

  const footerModalContent = () => {
    return (
      <div className="w-full h-full flex pb-4 justify-center">
        <ButtonComponent
          label="Cancelar"
          onClick={() => close(false)}
          className="p-button-text !bg-deleteButton !text-white"
        />
        <ButtonComponent label="Aceptar" onClick={() => deleteUser()} />
      </div>
    );
  };

  const deleteUser = async () => {
    let response: any = {};

    switch (catalog) {
      case 'Compañías de transporte':
        response = await deleteTransportCompaniesCatalog(itemToDelete.id);
        break;
      case 'Estaciones':
        response = await deleteStationsCatalog(itemToDelete.id);
        break;
      case 'Locaciones (locationId)':
        response = await deleteLocationsCatalog(itemToDelete.id);
        break;
      case 'Números económicos':
        response = await deleteEconomicNumbersCatalog(
          itemToDelete.economicNumberId
        );
        break;
      case 'Operadores tecnológicos':
        response = await deleteOperatorsCatalog(itemToDelete.id);
        break;
      case 'Puntos de venta':
        response = await deletePointsOfSalesCatalog(itemToDelete.id);
        break;
      case 'Proyectos':
        response = await deleteProjectsCatalog(itemToDelete.id);
        break;
      case 'Rutas':
        response = await deleteRoutesCatalog(itemToDelete.routeId);
        break;
      case 'Tipos de dispositivo':
        response = await deleteDeviceTypesCatalog(itemToDelete.deviceTypeId);
        break;
      default:
        break;
    }

    if (response.status === 200) {
      ToastComponent(
        'success',
        'Succes',
        'Se eliminó correctamente el registro.'
      );
      updateTable(response.status);
    }
  };

  return (
    <div>
      {visible === true ? (
        <DialogPopUpComponent
          show={visible}
          header={header}
          body={bodyModalContent()}
          footer={footerModalContent()}
          close={close}
        />
      ) : (
        <></>
      )}
    </div>
  );
};

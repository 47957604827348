import { IRoutes, IServiceMenuComponent } from '@/interfaces/interfaceCatalogs';
import { useFormik } from 'formik';
import { InputSwitchChangeEvent } from 'primereact/inputswitch';
import { classNames } from 'primereact/utils';
import { useEffect, useState } from 'react';

import { ButtonComponent } from '../../../../components/ButtonComponen';
import { InputComponent } from '../../../../components/InputComponent';
import { SwitchComponent } from '../../../../components/SwitchComponent';
import { useRoutesCatalogs } from '../../../../hooks/catalogs/useRoutesCatalogs';
import { createObjectAsInterface } from '../../../../utils/utils';
import { RoutesValidations } from './validations_form';

export const RoutesForm = (props: IServiceMenuComponent) => {
  const { dataRow, selectMenu, updateTable } = props;

  /**
   * Crea un objeto vacio para usarse en el initialValues de formik con la interface del formulario
   */
  const objAsTransportCompaniesInterface: IRoutes = {
    routeNumber: '',
    name: '',
    status: false,
  };

  /**
   * Estado que guarda el objeto creado de acuerdo a la interface del formulario
   */
  const [inputs, setInputs] = useState<IRoutes>(
    objAsTransportCompaniesInterface
  );

  /**
   * Texto del boton.
   * {string} Puede ser Agregar | Actualizar
   */
  const [buttonLabel, setButtonLabel] = useState<string>('Agregar');

  //Hooks

  const { putRoutesCatalog, postRoutesCatalog } = useRoutesCatalogs();

  /**
   * Si es una edicion de un item, se toma el objeto seleccionado y se le da el formato para que se usen los datos en el formik
   */
  useEffect(() => {
    if (dataRow !== undefined) {
      setButtonLabel('Actualizar');
      let obj = createObjectAsInterface(
        dataRow,
        objAsTransportCompaniesInterface
      );
      setInputs(obj);
    }
  }, [dataRow]);

  /**
   * Se manda la peticion al back
   */
  const handleSubmit = async (values: any) => {
    let response: any = {};

    if (buttonLabel === 'Actualizar') {
      values['routeId'] = dataRow.routeId;
      response = await putRoutesCatalog(values);
    } else if (buttonLabel === 'Agregar') {
      response = await postRoutesCatalog(values);
    }
    if (response.status === 200) {
      updateTable(response.status);
    }
  };

  const formik = useFormik({
    initialValues: inputs,
    enableReinitialize: true,
    validationSchema: RoutesValidations,
    onSubmit: data => {
      handleSubmit(data);
    },
  });

  const isFormFieldValid = (name: 'routeNumber' | 'name' | 'status') =>
    !!(formik.touched[name] && formik.errors[name]);

  const getFormErrorMessage = (name: 'routeNumber' | 'name' | 'status') => {
    return (
      isFormFieldValid(name) && (
        <small className="p-error">{formik.errors[name]}</small>
      )
    );
  };

  const renderMenu = (): any => {
    return (
      <form
        className="h-full w-full flex flex-col pt-5 px-6"
        onSubmit={formik.handleSubmit}>
        <div className="flex flex-col">
          <InputComponent
            label={'Nombre'}
            type={'text'}
            name={'name'}
            onChange={formik.handleChange}
            value={formik?.values?.['name'] || ''}
            placeholder={'Nombre'}
            className={classNames({
              'p-invalid': isFormFieldValid('name'),
            })}
          />
          {getFormErrorMessage('name')}
        </div>

        <div className="flex flex-col">
          <InputComponent
            label={'Número de ruta'}
            type={'text'}
            name={'routeNumber'}
            onChange={formik.handleChange}
            value={formik?.values?.['routeNumber'] || ''}
            placeholder={'Número de ruta'}
            className={classNames({
              'p-invalid': isFormFieldValid('routeNumber'),
            })}
          />
          {getFormErrorMessage('routeNumber')}
        </div>

        <div className="flex flex-col">
          <SwitchComponent
            checked={formik?.values?.['status'] || 'false'}
            onChange={(e: InputSwitchChangeEvent) => {
              formik.setFieldValue('status', e.value);
            }}
            label={'Estatus'}
            className={classNames({
              'p-invalid': isFormFieldValid('status'),
            })}
          />
          {getFormErrorMessage('status')}
        </div>

        <ButtonComponent className="mt-10" label={buttonLabel} type="submit" />
      </form>
    );
  };

  return (
    <div className="flex w-full h-full flex-col items-center bg-white  shadow-[-6px_-1px_5px_#00000024] z-10 right-0">
      <div className="flex w-full h-full overflow-y-auto">{renderMenu()}</div>
    </div>
  );
};

import { FunctionComponent, useContext, useEffect, useState } from 'react';

import { MainContainer } from '../../layout/components/MainContainer';
import { InventaryTable } from './components/InventoryTable';
import { RightMenu } from './components/rightMenu/RightMenu';
import { useInventoryService } from '../../hooks/inventory/useInventory';
import { StoreContext } from '../../context/Provider';

export const Inventory: FunctionComponent = () => {
  const {getInventoryData, inventoryExcel} = useInventoryService();
  const [openRightMenu, setOpenRightMenu] = useState(false);
  const [dataRow, setDataRow] = useState<any>(null);
  const [selectMenu, setSelectMenu] = useState<string>('');
  const [updateTable, setUpdateTable] = useState<any>(null);
  const {inventoryStateContext} = useContext(StoreContext);
  const [samListData, setSamListData] = useState<any>([]);
  const [samListDataTemp, setSamListDataTemp] = useState<any>([]);

  let samListDataCopy: any = [];

  useEffect(() => {
    if (inventoryStateContext.state.data !== null) {
      const {data} = inventoryStateContext.state;
      setSamListData(data);
      samListDataCopy = data;
      setSamListDataTemp(data);
    }
    
  }, [inventoryStateContext.state.data])
  

  const renderContent = () => {
    return (
      <div className="flex w-full h-full relative flex-row">
        <div className="flex grow h-full relative flex-col content-center items-center justify-center p-10 ">
          <InventaryTable
            inventoryExcel={inventoryExcel}
            getInventoryData={getInventoryData}
            loader={inventoryStateContext.state.loading}
            samListDataTemp={samListDataTemp}
            setSamListDataTemp={setSamListDataTemp}
            samListData={samListData}
            setOpenRightMenu={setOpenRightMenu}
            setDataRow={setDataRow}
            selectMenu={setSelectMenu}
            setUpdateTable={setUpdateTable}
          />
        </div>
      </div>
    );
  };

  const renderRightComponent = () => {
    return (
      <RightMenu
        dataRow={dataRow}
        selectMenu={selectMenu}
        setOpenRightMenu={setOpenRightMenu}
        updateTable={updateTable}
      />
    );
  };

  const loading = false;

  return (
    <MainContainer
      openRightMenu={openRightMenu}
      setOpenRightMenu={setOpenRightMenu}
      loading={loading}
      rightMenuContent={renderRightComponent}>
      {renderContent()}
    </MainContainer>
  );
};

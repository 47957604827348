import { useContext } from 'react';

import { ToastComponent } from '../../components/ToastComponent';
import {
  GET_DEVICETYPES_CATALOG,
  GET_DEVICETYPES_CATALOG_SUCCESS,
  GET_DEVICETYPES_CATALOG_ERROR,
  GET_EXPORTDEVICETYPES_CATALOG,
  GET_EXPORTDEVICETYPES_CATALOG_SUCCESS,
  GET_EXPORTDEVICETYPES_CATALOG_ERROR,
  POST_DEVICETYPES_CATALOG,
  POST_DEVICETYPES_CATALOG_SUCCESS,
  POST_DEVICETYPES_CATALOG_ERROR,
  PUT_DEVICETYPES_CATALOG,
  PUT_DEVICETYPES_CATALOG_SUCCESS,
  PUT_DEVICETYPES_CATALOG_ERROR,
  DELETE_DEVICETYPES_CATALOG,
  DELETE_DEVICETYPES_CATALOG_SUCCESS,
  DELETE_DEVICETYPES_CATALOG_ERROR,
} from '../../constants/constantsRed';
import { StoreContext } from '../../context/Provider';
import useApi from '../../services/request.service';
import { string } from 'yup';

export const useDeviceTypesCatalogs = () => {
  const context = useContext(StoreContext);
  const {
    dispatchGetDeviceTypesCatalog,
    dispatchPostExportDeviceTypesCatalog,
    dispatchDeleteDeviceTypesCatalog,
    dispatchPostDeviceTypesCatalog,
    dispatchPutDeviceTypesCatalog,
  } = context;
  const { genericApiConfiguration } = useApi();

  const getDeviceTypesCatalog = async () => {
    dispatchGetDeviceTypesCatalog({ type: GET_DEVICETYPES_CATALOG });
    try {
      const result: any = await genericApiConfiguration(
        '/device-types',
        'GET',
        {},
        {},
        {}
      );
      if (result.status === 200) {
        const payload = result.data.payload;
        dispatchGetDeviceTypesCatalog({
          type: GET_DEVICETYPES_CATALOG_SUCCESS,
          payload: payload,
        });
      }
      return result;
    } catch (error: any) {
      ToastComponent(
        'warning',
        'Error',
        'La operación falló, inténtelo más tarde por favor.'
      );
      dispatchGetDeviceTypesCatalog({
        type: GET_DEVICETYPES_CATALOG_ERROR,
        payload: error.message,
      });
      return 'error';
    }
  };

  const postExportDeviceTypesCatalog = async (
    pathSendExcel: string,
    formData: any
  ) => {
    dispatchPostExportDeviceTypesCatalog({
      type: GET_EXPORTDEVICETYPES_CATALOG,
    });
    try {
      const result: any = await genericApiConfiguration(
        pathSendExcel + '/import',
        'POST',
        formData,
        {},
        { 'Content-Type': 'multipart/form-data' }
      );

      if (result.status !== 200 &&!result?.response?.data?.success) {
        let isArray       = false;
        let messages      = result?.response?.data?.message;

        if(Array.isArray(messages)){
          isArray         = true;
          messages.forEach(
            (message: string) => 
            {
              ToastComponent('warning', 'Error', message);
            }
          );
        }

        if(!isArray)
          ToastComponent('warning', 'Error', messages);
        
        dispatchPostExportDeviceTypesCatalog({
          type: GET_EXPORTDEVICETYPES_CATALOG_ERROR,
          payload: result?.response?.data?.message,
        });

        return result;
      }

      const payload     = result.data.payload;
        dispatchPostExportDeviceTypesCatalog({
          type: GET_EXPORTDEVICETYPES_CATALOG_SUCCESS,
          payload: payload,
        });
        ToastComponent('success', 'Succes', result.data?.message);
      return result;
    } catch (error: any) {
      dispatchPostExportDeviceTypesCatalog({
        type: GET_EXPORTDEVICETYPES_CATALOG_ERROR,
        payload: error.message,
      });
      return 'error';
    }
  };

  const postDeviceTypesCatalog = async (data: any) => {
    dispatchPostDeviceTypesCatalog({ type: POST_DEVICETYPES_CATALOG });
    try {
      const result: any = await genericApiConfiguration(
        '/device-types',
        'POST',
        data,
        {},
        {}
      );
      if (result.status === 200) {
        ToastComponent('success', '', 'Se ha añadido el registro correctamente');
        const payload = result.data.payload;
        dispatchPostDeviceTypesCatalog({
          type: POST_DEVICETYPES_CATALOG_SUCCESS,
          payload: payload,
        });
      }
      return result;
    } catch (error: any) {
      dispatchPostDeviceTypesCatalog({
        type: POST_DEVICETYPES_CATALOG_ERROR,
        payload: error.message,
      });
      return 'error';
    }
  };

  const putDeviceTypesCatalog = async (data: any) => {
    dispatchPutDeviceTypesCatalog({ type: PUT_DEVICETYPES_CATALOG });
    try {
      const result: any = await genericApiConfiguration(
        '/device-types',
        'PUT',
        data,
        {},
        {}
      );
      if (result.status === 200) {
        ToastComponent('success', '', 'Se ha editado el registro correctamente');
        const payload = result.data.payload;
        dispatchPutDeviceTypesCatalog({
          type: PUT_DEVICETYPES_CATALOG_SUCCESS,
          payload: payload,
        });
      }
      return result;
    } catch (error: any) {
      dispatchPutDeviceTypesCatalog({
        type: PUT_DEVICETYPES_CATALOG_ERROR,
        payload: error.message,
      });
      return 'error';
    }
  };

  const deleteDeviceTypesCatalog = async (id: string) => {
    dispatchDeleteDeviceTypesCatalog({ type: DELETE_DEVICETYPES_CATALOG });
    try {
      const result: any = await genericApiConfiguration(
        '/device-types/' + id,
        'DELETE',
        {},
        {},
        {}
      );

      if (result.status === 200) {
        const payload = result.data.payload;
        dispatchDeleteDeviceTypesCatalog({
          type: DELETE_DEVICETYPES_CATALOG_SUCCESS,
          payload: payload,
        });
      }
      return result;
    } catch (error: any) {
      dispatchDeleteDeviceTypesCatalog({
        type: DELETE_DEVICETYPES_CATALOG_ERROR,
        payload: error.message,
      });
      return 'error';
    }
  };

  return {
    getDeviceTypesCatalog,
    postExportDeviceTypesCatalog,
    postDeviceTypesCatalog,
    putDeviceTypesCatalog,
    deleteDeviceTypesCatalog,
  };
};

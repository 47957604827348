import { useContext } from 'react';

import { ToastComponent } from '../../components/ToastComponent';
import {
  GET_LOCATIONS_CATALOG,
  GET_LOCATIONS_CATALOG_SUCCESS,
  GET_LOCATIONS_CATALOG_ERROR,
  POST_LOCATIONS_CATALOG,
  POST_LOCATIONS_CATALOG_SUCCESS,
  POST_LOCATIONS_CATALOG_ERROR,
  PUT_LOCATIONS_CATALOG,
  PUT_LOCATIONS_CATALOG_SUCCESS,
  PUT_LOCATIONS_CATALOG_ERROR,
  DELETE_LOCATIONS_CATALOG,
  DELETE_LOCATIONS_CATALOG_SUCCESS,
  DELETE_LOCATIONS_CATALOG_ERROR,
} from '../../constants/constantsRed';
import { StoreContext } from '../../context/Provider';
import useApi from '../../services/request.service';

export const useLocationCatalogs = () => {
  const context = useContext(StoreContext);
  const {
    dispatchGetLocationCatalog,
    dispatchPostLocationsCatalog,
    dispatchPutLocationsCatalog,
    dispatchDeleteLocationsCatalog,
  } = context;
  const { genericApiConfiguration } = useApi();

  const getLocationCatalog = async () => {
    dispatchGetLocationCatalog({ type: GET_LOCATIONS_CATALOG });
    try {
      const result: any = await genericApiConfiguration(
        '/locations',
        'GET',
        {},
        {},
        {}
      );
      if (result.status === 200) {
        const payload = result.data.payload;
        dispatchGetLocationCatalog({
          type: GET_LOCATIONS_CATALOG_SUCCESS,
          payload: payload,
        });
      }
      return result;
    } catch (error: any) {
      ToastComponent(
        'warning',
        'Error',
        'La operación falló, inténtelo más tarde por favor.'
      );
      dispatchGetLocationCatalog({
        type: GET_LOCATIONS_CATALOG_ERROR,
        payload: error.message,
      });
      return 'error';
    }
  };

  const postLocationsCatalog = async (data: any) => {
    dispatchPostLocationsCatalog({ type: POST_LOCATIONS_CATALOG });
    try {
      const result: any = await genericApiConfiguration(
        '/locations',
        'POST',
        data,
        {},
        {}
      );
      if (result.status === 200) {
        ToastComponent('success', '', 'Se ha añadido el registro correctamente');
        const payload = result.data.payload;
        dispatchPostLocationsCatalog({
          type: POST_LOCATIONS_CATALOG_SUCCESS,
          payload: payload,
        });
      }
      return result;
    } catch (error: any) {
      dispatchPostLocationsCatalog({
        type: POST_LOCATIONS_CATALOG_ERROR,
        payload: error.message,
      });
      return 'error';
    }
  };

  const putLocationsCatalog = async (data: any) => {
    dispatchPutLocationsCatalog({ type: PUT_LOCATIONS_CATALOG });
    try {
      const result: any = await genericApiConfiguration(
        '/locations',
        'PUT',
        data,
        {},
        {}
      );
      if (result.status === 200) {
        ToastComponent('success', '', 'Se ha editado el registro correctamente');
        const payload = result.data.payload;
        dispatchPutLocationsCatalog({
          type: PUT_LOCATIONS_CATALOG_SUCCESS,
          payload: payload,
        });
      }
      return result;
    } catch (error: any) {
      dispatchPutLocationsCatalog({
        type: PUT_LOCATIONS_CATALOG_ERROR,
        payload: error.message,
      });
      return 'error';
    }
  };

  const deleteLocationsCatalog = async (id: string) => {
    dispatchDeleteLocationsCatalog({ type: DELETE_LOCATIONS_CATALOG });
    try {
      const result: any = await genericApiConfiguration(
        '/locations/{id}',
        'DELETE',
        {},
        { ids: id },
        {}
      );

      if (result.status === 200) {
        const payload = result.data.payload;
        dispatchDeleteLocationsCatalog({
          type: DELETE_LOCATIONS_CATALOG_SUCCESS,
          payload: payload,
        });
      }
      return result;
    } catch (error: any) {
      dispatchDeleteLocationsCatalog({
        type: DELETE_LOCATIONS_CATALOG_ERROR,
        payload: error.message,
      });
      return 'error';
    }
  };

  return {
    getLocationCatalog,
    postLocationsCatalog,
    putLocationsCatalog,
    deleteLocationsCatalog,
  };
};

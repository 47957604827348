import { Column } from 'primereact/column';
import { FormEvent, useEffect, useState } from 'react';
import { FaPlus } from 'react-icons/fa6';
import { LuFileEdit, LuTrash2 } from 'react-icons/lu';

import { useDeviceTypesCatalogs } from '../../../hooks/catalogs/useDeviceTypesCatalogs';
import { useEconomicNumbersCatalogs } from '../../../hooks/catalogs/useEconomicNumbersCatalogs';
import { useLocationCatalogs } from '../../../hooks/catalogs/useLocationsCatalogs';
import { useOperatorsCatalogs } from '../../../hooks/catalogs/useOperatorsCatalogs';
import { usePcModulesCatalog } from '../../../hooks/catalogs/usePcModulesCatalog';
import { usePointsOfSalesCatalog } from '../../../hooks/catalogs/usePointsOfSalesCatalog';
import { usegetProjectsCatalogs } from '../../../hooks/catalogs/useProjectsCatalog';
import { useRoutesCatalogs } from '../../../hooks/catalogs/useRoutesCatalogs';
import { useStationsCatalogs } from '../../../hooks/catalogs/useStationsCatalogs';
import { useTransportCompaniesCatalogs } from '../../../hooks/catalogs/useTransportCompaniesCatalogs';
import useApi from '../../../services/request.service';
import { validatePermissions } from '../../../utils/utils';
import { ButtonComponent } from './../../../components/ButtonComponen';
import { LoaderComponent } from './../../../components/LoaderComponent';
import { TableComponent } from './../../../components/Table';
import { ITableCatalogProps } from './../../../interfaces/interfaceCatalogs';
import { PermitsList } from './../../../permitsList';
import { DeleteItemModal } from './DeleteItemModal';
import {
  deviceTypesColumns,
  economicNumberColumns,
  locationsColumns,
  operatorsColumns,
  pcModuleColumns,
  pointOfSaleColumns,
  projectsColumns,
  routesColumns,
  stationsColumns,
  transportCompaniesColumns,
} from './forms/utils/getColumns';

export const TableCatalogComponent = (props: ITableCatalogProps) => {
  const {
    titleProp,
    documentName,
    loading,
    setOpenRightMenu,
    setDataRow,
    selectMenu,
    setUpdateTable,
  } = props;

  const { postExportDeviceTypesCatalog, getDeviceTypesCatalog } =
    useDeviceTypesCatalogs();

  const { getLocationCatalog } = useLocationCatalogs();
  const { getPcModulesCatalog } = usePcModulesCatalog();
  const { getOperatorsCatalog } = useOperatorsCatalogs();
  const { getEconomicNumbersCatalog } = useEconomicNumbersCatalogs();
  const { getPointsOfSalesCatalog } = usePointsOfSalesCatalog();
  const { getProjectsCatalog } = usegetProjectsCatalogs();
  const { getRoutesCatalog } = useRoutesCatalogs();
  const { getStationsCatalog } = useStationsCatalogs();

  const { getTransportCompaniesCatalog } = useTransportCompaniesCatalogs();

  const { dowloadExcel } = useApi();

  //States
  const [sort, setSort] = useState([{ field: '', order: -1 }]);
  const [columns, setColumns] = useState<any>([]);
  const [dataCatalogTable, setDataCatalogTable] = useState<any>([]);
  const [title, setTitle] = useState<string>('sin datos');
  const [pathSendExcel, setPathSendExcel] = useState<string>('');
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [itemToDelete, setItemToDelete] = useState<any>();

  const [file, setFile] = useState<any | null>(null);

  useEffect(() => {
    if (titleProp) {
      setTitle(titleProp || '');

      if (title !== titleProp) {
        getData();
        const inputFile = document.getElementById('file') as HTMLInputElement;
        if (inputFile) {
          inputFile.value = '';
          setFile(null);
        }
      }
    }
  }, [props]);

  async function getData() {
    let response: any = {};
    switch (titleProp) {
      case 'Compañías de transporte':
        setColumns(transportCompaniesColumns);
        response = await getTransportCompaniesCatalog();
        setPathSendExcel('/transport-companies');
        if (response.status === 200) {
          setDataCatalogTable(response.data.message);
        }
        break;
      case 'Estaciones':
        setColumns(stationsColumns);
        response = await getStationsCatalog();
        setPathSendExcel('/stations');
        if (response.status === 200) {
          setDataCatalogTable(response.data.message);
        }
        break;
      case 'Locaciones (locationId)':
        setColumns(locationsColumns);
        response = await getLocationCatalog();
        setPathSendExcel('/locations');
        if (response.status === 200) {
          setDataCatalogTable(response.data.message);
        }
        break;
      case 'Módulos PC':
        setColumns(pcModuleColumns);
        response = await getPcModulesCatalog();
        setPathSendExcel('/pc-modules');
        if (response.status === 200) {
          setDataCatalogTable(response.data.message);
        }
        break;
      case 'Números económicos':
        setColumns(economicNumberColumns);
        response = await getEconomicNumbersCatalog();
        setPathSendExcel('/economic-numbers');
        if (response.status === 200) {
          setDataCatalogTable(response.data.message);
        }
        break;
      case 'Operadores tecnológicos':
        setColumns(operatorsColumns);
        response = await getOperatorsCatalog();
        setPathSendExcel('/operators');
        if (response.status === 200) {
          setDataCatalogTable(response.data.message);
        }
        break;
      case 'Puntos de venta':
        setColumns(pointOfSaleColumns);
        response = await getPointsOfSalesCatalog();
        setPathSendExcel('/pos');
        if (response.status === 200) {
          setDataCatalogTable(response.data.message);
        }
        break;
      case 'Proyectos':
        setColumns(projectsColumns);
        response = await getProjectsCatalog();
        setPathSendExcel('/projects');
        if (response.status === 200) {
          setDataCatalogTable(response.data.message);
        }
        break;
      case 'Rutas':
        setColumns(routesColumns);
        response = await getRoutesCatalog();
        setPathSendExcel('/routes');
        if (response.status === 200) {
          setDataCatalogTable(response.data.message);
        }
        break;
      case 'Tipos de dispositivo':
        setColumns(deviceTypesColumns);
        response = await getDeviceTypesCatalog();
        setPathSendExcel('/device-types');
        if (response.status === 200) {
          setDataCatalogTable(response.data.message);
        }
        break;

      default:
        break;
    }
  }

  async function handleSubmit(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();
    
    if (file) {
      const formData = new FormData();
      formData.append('file', file);

      let response = await postExportDeviceTypesCatalog(
        pathSendExcel,
        formData
      );
      if (response.status === 200) {
        getData();
      }
      setFile(null);
    }
  }

  function handleFileChange(event: any) {
    const fileInput = event.target.files;
    if (event.target.files && event.target.files[0]) {
      setFile(event.target.files[0]);
      event.target.value = null;
    }
  }

  function updateTable(status: number) {
    if (status === 200) {
      getData();
      setShowDeleteModal(false);
      setOpenRightMenu(false);
    }
  }

  function openRightMenu(row?: any) {
    row !== undefined ? setDataRow(row) : setDataRow();

    setOpenRightMenu(true);
    selectMenu(titleProp);
    setUpdateTable(() => updateTable);
  }

  const renderTitle = () => {
    return (
      <div className="flex justify-between w-full h-auto pb-2">
        <span className="text-3xl font-medium">{title}</span>
        <div className="flex h-12 items-center text-xl cursor-pointer">
          {validatePermissions(PermitsList.CATALOGS_ADD) ? (
            <ButtonComponent
              label={'Agregar'}
              className="w-auto h-12 bg-white border-solid border border-alternative_green text-alternative_green hover:bg-alternative_green"
              onClick={() => {
                openRightMenu();
              }}
              iconPos="left"
              icon="pi pi-plus"
            />
          ) : (
            <></>
          )}
        </div>
      </div>
    );
  };

  function exportTemplateExcel() {
    dowloadExcel(pathSendExcel, documentName, '/template');
  }

  function exportExcel() {
    dowloadExcel(pathSendExcel, documentName, '');
  }

  const renderFiltersAndExports = () => {
    return (
      <div className="flex w-full h-auto relative flex-row justify-between gap-2 pb-2">
        {validatePermissions(PermitsList.CATALOGS_IMPORT) ? (
          <form className="flex w-full h-full gap-4" onSubmit={handleSubmit}>
            <input
              id="file"
              name="file"
              type="file"
              className="w-auto h-12 absolute opacity-0 cursor-pointer invisible"
              onChange={handleFileChange}
            />
            <label
              htmlFor="file"
              className="w-1/4 h-12 flex cursor-pointer justify-center items-center text-white bg-official_green hover:bg-alternative_green">
              {file === null ? (
                <span className="cursor-pointer">Subir archivo (.xlsx)</span>
              ) : (
                <span className="cursor-pointer">Archivo cargado</span>
              )}
            </label>
            {file !== null ? (
              <ButtonComponent
                label={'Importar'}
                className="w-1/4 h-12"
                severity="success"
                outlined={true}
                type="submit"
              />
            ) : (
              <></>
            )}
          </form>
        ) : (
          <></>
        )}

        {validatePermissions(PermitsList.CATALOGS_EXPORT) ? (
          <>
            <ButtonComponent
              label={'Exportar plantilla'}
              className="w-1/4 h-12 bg-white border-solid border border-alternative_green text-alternative_green hover:bg-alternative_green"
              onClick={exportTemplateExcel}
            />

            <ButtonComponent
              label={'Exportar'}
              className="w-1/4 h-12 bg-white border-solid border border-alternative_green text-alternative_green hover:bg-alternative_green"
              onClick={exportExcel}
            />
          </>
        ) : (
          <></>
        )}
      </div>
    );
  };

  const openDeleteModal = (row?: any) => {
    setShowDeleteModal(true);
    setItemToDelete(row);
  };

  /**
   * Regresa la columa de acciones, icono de edicion y eliminacion de cada item
   * @param row El resgistro que se selecciono en la tabla
   */
  const actionsRow = (row: any) => {
    return (
      <div className="flex w-full justify-around text-xl">
        {validatePermissions(PermitsList.CATALOGS_EDIT) ? (
          <LuFileEdit
            className="cursor-pointer text-official_green"
            onClick={() => openRightMenu(row)}
          />
        ) : (
          <></>
        )}
        {validatePermissions(PermitsList.CATALOGS_DELETE) ? (
          <LuTrash2
            className="cursor-pointer text-red"
            onClick={() => openDeleteModal(row)}
          />
        ) : (
          <></>
        )}
      </div>
    );
  };

  const BodyTemplate = (item: any, key: any) => {
    let textColor = item[key.field];
    if (
      key.field === 'id' &&
      item['samTypeName'] === null &&
      item['transportCompanyName'] === null
    )
      return labelTemplate(textColor);

    return textColor;
  };

  const labelTemplate = (text: any) => {
    return (
      <span
        className="!font-bold !text-[#f59e0b]"
        title="Advertencia: Se necesita completar los datos correspondientes">
        {text}
      </span>
    );
  };

  const generateColumns = () => {
    let listColumn = columns.map((column: any, index: any) => {
      const key = index.toString();
      return (
        <Column
          key={key}
          field={column.field}
          header={column.header}
          bodyClassName={`!text-black`}
          style={{
            flexGrow: 1,
            flexBasis: '100px',
          }}
          headerClassName="!bg-tableHeader !text-withe!justify-between"
          sortable={column.sortable}
          filterMenuStyle={{ width: '14rem' }}
          filterElement={column.filterElement}
          filter={column.filter}
          body={BodyTemplate}
        />
      );
    });
    listColumn.push(
      <Column key={columns.length + 1} header={'Acciones'} body={actionsRow} />
    );
    return listColumn;
  };

  const renderContent = () => {
    return (
      <div className="flex w-full h-full relative flex-row ">
        <TableComponent
          value={dataCatalogTable}
          sortField="name"
          responsiveLayout="scroll"
          globalFilterFields={[]}
          resizableColumns
          setSort={setSort}
          sort={sort}>
          {generateColumns()}
        </TableComponent>
      </div>
    );
  };

  function handlerCloseModal() {
    setShowDeleteModal(false);
  }

  const loader = ()=> {

    return loading ? 
      <LoaderComponent /> : <></>
  }
  return (
    <>
      {title !== 'sin datos' ? (
        <>
            {loader()}
            {renderTitle()} {renderFiltersAndExports()} {renderContent()}
        </>
      ) : (
        <span className="text-3xl">Seleccione un catálogo</span>
      )}
      {showDeleteModal ? (
        <DeleteItemModal
          itemToDelete={itemToDelete}
          handlerCloseModal={handlerCloseModal}
          showDeleteModal={showDeleteModal}
          catalog={titleProp}
          updateTable={updateTable}
        />
      ) : (
        <></>
      )}
    </>
  );
};

import { useContext, useEffect, useState } from 'react';
import {
  BsBicycle,
  BsBuilding,
  BsBusFront,
  BsCashCoin,
  BsClipboard2Data,
  BsLamp,
  BsMemory,
  BsPcDisplayHorizontal,
  BsPinMapFill,
  BsSdCard,
} from 'react-icons/bs';

interface ICatalogListProps {
  setCatalog: any;
  setDocumentName: any;
  setOpenRightMenu: any;
}

export const CatalogsList = (props: ICatalogListProps) => {
  const { setCatalog, setDocumentName, setOpenRightMenu } = props;
  //states
  const [selectCatalog, setSelectCatalog] = useState<string>('');

  const [elementList] = useState<any>([
    {
      name: 'Compañías de transporte',
      icon: <BsMemory />,
      id: 1,
      document_name: 'Compañías de transporte',
    },
    { name: 'Estaciones', icon: <BsLamp />, document_name: 'Estaciones' },
    {
      name: 'Locaciones (locationId)',
      icon: <BsPinMapFill />,
      document_name: 'Locaciones',
    },
/*     {
      name: 'Módulos PC',
      icon: <BsPcDisplayHorizontal />,
      document_name: 'Módulos PC',
    }, */
    {
      name: 'Números económicos',
      icon: <BsBusFront />,
      document_name: 'Números económicos',
    },
    {
      name: 'Operadores tecnológicos',
      icon: <BsBuilding />,
      document_name: 'Operadores tecnológicos',
    },
    {
      name: 'Puntos de venta',
      icon: <BsCashCoin />,
      document_name: 'Puntos de venta',
    },
    {
      name: 'Proyectos',
      icon: <BsClipboard2Data />,
      document_name: 'Proyectos',
    },
    { name: 'Rutas', icon: <BsBicycle />, document_name: 'Rutas' },
    {
      name: 'Tipos de dispositivo',
      icon: <BsSdCard />,
      document_name: 'Tipos de dispositivo',
    },
  ]);

  const catalogSelect = (item: any) => {
    setCatalog(item.name);
    setSelectCatalog(item.name);
    setDocumentName(item.document_name);
    setOpenRightMenu(false);
  };

  const renderMenu = () => {
    return (
      <div className="h-full w-full flex flex-col">
        {elementList.map((item: any, index: number) => {
          return (
            <div
              className={`flex flex-col mt-5 justify-center text-center cursor-pointer text-grey  hover:!text-official_green ${
                item.name === selectCatalog ? 'text-official_green' : ''
              }`}
              key={index}
              onClick={() => catalogSelect(item)}>
              <div className="flex justify-center text-4xl ">{item.icon}</div>
              <div className="flex justify-center">{item.name}</div>
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <div className="flex h-full w-full px-12 overflow-y-auto absolute">
      {renderMenu()}
    </div>
  );
};
